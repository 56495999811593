import history from "../history"

function getRoute() {
    return history.location.pathname
}

function navigate(path) {
    return history.push(path)
}

function goBack() {
    return history.goBack()
}

export default {
    getRoute,
    navigate,
    goBack
}