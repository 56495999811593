import React, { Component, Fragment } from "react";
import { strings } from "../../resources/strings";
import { connect } from "react-redux";
import { appUtils } from "../../utils/appUtils";

class Footer extends Component {

    render() {

        if (appUtils.isEpmtyObject(this.props.admin)) {
            return <Fragment/>;
        }

        return (
            <div className="row w-100 justify-content-center footer m-0">
                <div className="flex-column d-flex align-items-center">
                    <img className="footer-logo" src="/swissYouLogo.png" alt="swiss-you"/>
                    <p className="footer-text">{ strings.allRightsReserved }</p>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {admin: state.admin.myAccount};
};

export default connect(mapStateToProps)(Footer);