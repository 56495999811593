import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { strings } from "../../../resources/strings";
import Section from "../../common/Section";
import { Fields } from "../../common/Fields";
import FormButton from "../../common/buttons/FormButton";
import { reduxForm } from "redux-form";
import { formUtils } from "../../../utils/formUtils";
import { createAdmin } from "../../../actions";
import { reset } from "redux-form";
import store from "../../../store";

class CreateAdmin extends Component {

    componentDidMount() {
        store.dispatch(reset("createAdminForm"));
    }

    onFormSubmit = (formValues) => {
        this.props.createAdmin(formValues);
    };

    render() {
        return (
            <Fragment>
                <Section title={ strings.createAdmin } first/>
                <form className="align-self-center"
                      onSubmit={ this.props.handleSubmit(this.onFormSubmit) }
                >
                    { Fields.firstName }
                    { Fields.lastName }
                    { Fields.email }
                    { Fields.password }
                    { Fields.repeatPassword }
                    <div className="col-4 p-0">
                        <FormButton
                            onPress={ this.onFormSubmit }
                            className="margin-top-m"
                            title={ strings.create }
                        />
                    </div>
                </form>
            </Fragment>
        );
    }
}

const createAdminForm = reduxForm({form: "createAdminForm", validate: formUtils.validate})(CreateAdmin);

const mapStateToProps = state => {
    return {
        admin: state.admin.myAccount
    };
};

export default connect(mapStateToProps, {createAdmin})(createAdminForm);