import React, { Component } from "react";
import { faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faSkype, faViber, faWeixin, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import find from "lodash/find";
import { contactProviders } from "../../constants/app";
import { strings } from "../../resources/strings";

class Contacts extends Component {

    getContact = (provider) => {
        const contact = find(this.props.contacts, ["provider", provider]);
        if (!contact) {
            return <span>{ strings.notSet }</span>;
        }

        return <span>{ contact.contact }</span>;
    };

    renderContacts = () => {
        const contacts = [
            {
                icon: faPhone,
                color: "#81cd50",
                contact: this.getContact(contactProviders.PHONE)
            },
            {
                icon: faSkype,
                color: "#00aff0",
                contact: this.getContact(contactProviders.SKYPE)
            },
            {
                icon: faViber,
                color: "#59267c",
                contact: this.getContact(contactProviders.VIBER)
            },
            {
                icon: faWhatsapp,
                color: "#075e54",
                contact: this.getContact(contactProviders.WHATS_APP)
            },
            {
                icon: faWeixin,
                color: "#7bb32e",
                contact: this.getContact(contactProviders.WE_CHAT)
            },
            {
                icon: faGlobe,
                color: "#6a737b",
                contact: this.getContact(contactProviders.OTHER)
            }
        ];

        return contacts.map((contact, index) => {
            return (
                <div key={ "c" + index } className="row align-items-center">
                    <FontAwesomeIcon className="contact-icon" icon={ contact.icon } color={contact.color}/>
                    { contact.contact }
                </div>);
        });

    };

    render() {

        return (
            <div className="contacts">
                <strong>Contacts</strong>
                { this.renderContacts() }
            </div>
        );
    }
}

export default Contacts;