import React, { Component } from "react";
import { connect } from "react-redux";
import { appUtils } from "../../../utils/appUtils";
import Table from "../../common/Table";
import { strings } from "../../../resources/strings";
import Section from "../../common/Section";
import Pagination from "../../common/Pagination";
import { getUserTransactions, setPagination } from "../../../actions";
import { paginationTypes, userTypes } from "../../../constants/app";

class Transactions extends Component {

    setCurrentPage = (page) => {
        this.props.setPagination(paginationTypes.USER_TRANSACTIONS, page);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {currentUser} = this.props;
        if (prevProps.page !== this.props.page) {
            this.props.getUserTransactions(currentUser.id, currentUser[userTypes.ADVISOR.toLowerCase()].id, this.props.page);
        }
    }

    renderTransactions = () => {

        let transactions = this.props.currentUser.transactions;

        if (transactions) {
            transactions = appUtils.parseTransactions(transactions.data);
        } else {
            transactions = [];
        }

        return <Table
            columns={ [strings.ID, strings.requests, strings.IBAN, strings.IBANHolderName, strings.date] }
            data={ transactions }
            noItemsMessage={ strings.noTransactions }
        />;
    };

    renderPagination = () => {
        const {transactions} = this.props.currentUser;
        if (transactions) {
            return (
                <Pagination
                    currentPage={ this.props.page }
                    total={ transactions.total }
                    setCurrentPage={ this.setCurrentPage }
                />
            );
        }
    };

    render() {
        return (
            <div className="row p-0">
                <div className="col-12">
                    <Section title={ strings.transactions }/>
                    { this.renderTransactions() }
                    { this.renderPagination() }
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {currentUser: state.users.currentUser, page: state.pagination[paginationTypes.USER_TRANSACTIONS]};
};

export default connect(mapStateToProps, {getUserTransactions, setPagination})(Transactions);