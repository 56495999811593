import { actions } from "../constants/actions";

const initState = {dialogSmall:{}, dialogLarge:{}};

const dialogReducer = (state = {...initState}, action) => {

    if (action.type === actions.SHOW_DIALOG) {
        state.dialogSmall = {...action.payload, showDialog: true};
        return {...state};
    }

    if (action.type === actions.HIDE_DIALOG) {
        state.dialogSmall.showDialog = false;
        state.dialogSmall = {...state.dialogSmall};
        return {...state};

    }

    if (action.type === actions.SHOW_DIALOG_LARGE) {
        state.dialogLarge = {...action.payload, showDialog: true};
        return {...state};
    }

    if (action.type === actions.HIDE_DIALOG_LARGE) {
        state.dialogLarge.showDialog = false;
        state.dialogLarge = {...state.dialogLarge};
        return {...state};

    }

    return state;
};

export default dialogReducer;