import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { appUtils } from "../../utils/appUtils";
import {
    faUsers,
    faHome,
    faTasks,
    faCoins,
    faExclamationTriangle,
    faCog,
    faUserSlash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { strings } from "../../resources/strings";
import { Link } from "react-router-dom";
import navigationUtils from "../../utils/navigationUtils";
import { pagesPaths } from "../../constants/pagesPaths";
import history from "../../history";
import { clearPagination } from "../../actions";

class SideBar extends Component {

    state = {
        page: navigationUtils.getRoute()
    };

    componentDidMount() {

        this.navigationListener = history.listen(({pathname}) => {
            this.navigationHandler(pathname);
        });
    }

    navigationHandler = (pathname) => {
        if (appUtils.isEpmtyObject(this.props.admin) && this.state.page !== pagesPaths.DASHBOARD) {
            this.setState({page: pagesPaths.DASHBOARD});
        } else if (pathname === pagesPaths.ADMIN) {
            this.setState({page: pagesPaths.DASHBOARD});
        } else {
            if (this.state.page !== pathname) {
                this.setState({page: pathname});
            }
        }
    };

    componentWillUnmount() {
        this.navigationListener();
    }

    renderButtons = () => {

        const buttons = [
            {
                name: strings.dashboard,
                icon: faHome,
                path: pagesPaths.DASHBOARD
            },
            {
                name: strings.users,
                icon: faUsers,
                path: pagesPaths.USERS
            },
            {
                name: strings.requests,
                icon: faTasks,
                path: pagesPaths.REQUESTS
            },
            {
                name: strings.pendingPayments,
                icon: faCoins,
                path: pagesPaths.PENDING_PAYMENTS
            },
            {
                name: strings.conflicts,
                icon: faExclamationTriangle,
                path: pagesPaths.CONFLICTS
            },
            {
                name: strings.blockedUsers,
                icon: faUserSlash,
                path: pagesPaths.BLOCKED_USERS
            },
            {
                name: strings.settings,
                icon: faCog,
                path: pagesPaths.SETTINGS
            }
        ];

        return buttons.map((button) => {
            return this.renderButton(button);
        });
    };

    onClick = page => {
        this.props.clearPagination();
        this.setState({page});
    };

    renderButton = (button) => {

        let buttonStyle = "d-flex";

        if (button.path.split("/")[button.path.split("/").length-1] === this.state.page.split("/")[button.path.split("/").length-1]) {
            buttonStyle += " active";
        }

        return (
            <Link key={ button.path } onClick={ () => {
                this.onClick(button.path);
            } } to={ button.path } className="side-bar-menu">
                <div className={ buttonStyle }>
                    <FontAwesomeIcon icon={ button.icon } className="side-bar-icon text-center"/>
                    <span>{ button.name }</span>
                </div>
            </Link>
        );
    };

    render() {

        if (appUtils.isEpmtyObject(this.props.admin)) {
            return <Fragment/>;
        }

        return (
            <div className="side-bar-container">
                { this.renderButtons() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {admin: state.admin.myAccount};
};

export default connect(mapStateToProps, {clearPagination})(SideBar);
