import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { appUtils } from "../../utils/appUtils";
import {signOut} from "../../actions";
import { strings } from "../../resources/strings";

class HeaderBar extends Component {

    onLogoutClick = () => {
        this.props.signOut()
    };

    render() {

        if (appUtils.isEpmtyObject(this.props.admin)) {
            return <Fragment/>;
        }

        const welcomeMessage = strings.hi + this.props.admin.firstName + " " + this.props.admin.lastName;

        return (
            <div className="fixed-top header-bar-container d-flex">
                <div className="my-auto header-bar-text d-inline welcome">
               { welcomeMessage }
                </div>
                <div onClick={this.onLogoutClick} className="my-auto d-inline sign-out-button text-button">
                    {strings.signOut}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {admin: state.admin.myAccount};
};

export default connect(mapStateToProps, {signOut})(HeaderBar);