import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import TopicSection from "../../common/TopicSection";
import { strings } from "../../../resources/strings";
import Table from "../../common/Table";
import Details from "../../common/Details";
import Detail from "../../common/Detail";
import {
    exportData,
    getAdvisorsPendingPayments,
    selectPendingPaymentRequest,
    setPagination,
    updatePendingPaymentRequests
} from "../../../actions";
import { appUtils } from "../../../utils/appUtils";
import Pagination from "../../common/Pagination";
import lodash from "lodash";
import { paginationTypes } from "../../../constants/app";

class PendingPaymentsPage extends Component {

    onCheckboxPress = id => {
        this.props.selectPendingPaymentRequest(id);
    };

    onGlobalCheckboxPress = checks => {
        this.props.updatePendingPaymentRequests(checks);
    };

    componentDidMount() {
        this.props.getAdvisorsPendingPayments(this.props.page);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.page !== this.props.page) {
            this.props.getAdvisorsPendingPayments(this.props.page);
            return;
        }

        const {advisorsPendingPayments} = this.props;
        const advisorWithoutRequest = lodash.find(advisorsPendingPayments.data, function (advisor) {
            return advisor.requests.length === 0;
        });

        if (advisorWithoutRequest) {
            this.props.getAdvisorsPendingPayments(this.props.page);
        }
    }

    setCurrentPage = (page) => {
        this.props.setPagination(paginationTypes.PENDING_PAYMENTS, page);
    };

    renderPagination = () => {

        const {advisorsPendingPayments} = this.props;

        if (advisorsPendingPayments) {
            return (
                <Pagination
                    currentPage={ this.props.page }
                    total={ advisorsPendingPayments.total }
                    setCurrentPage={ this.setCurrentPage }
                />
            );
        }
    };

    renderAdvisorPendingPaymendsDetails = advisor => {

        const name = advisor.user.firstName + " " + advisor.user.lastName;
        const bankAccoutn = advisor.bankAccount ? advisor.bankAccount : strings.notSet;
        const bankAccountHolderName = advisor.bankAccountHolderName ? advisor.bankAccountHolderName : strings.notSet;

        return (<Details white>
            <div className="col-6">
                <Detail title={ strings.advisorName } value={ name }/>
                <Detail title={ strings.advisorId } value={ advisor.userId }/>
            </div>
            <div className="col-6">
                <Detail title={ strings.IBAN } value={ bankAccoutn }/>
                <Detail title={ strings.IBANHolderName } value={ bankAccountHolderName }/>
            </div>
        </Details>);
    };

    renderAdvisorPendingPaymends = (advisor, amount) => {

        return (
            <Fragment>
                <span className="dialog-category-title">{ strings.notPaidRequests }</span>
                <div className="table-dialog">
                    <Table
                        columns={ [strings.requestId, strings.client, strings.startDate, strings.advisorProfit, strings.action] }
                        data={ appUtils.parsePendingPaymentsRequests(advisor.requests) }
                        checkbox
                        onCheckboxPress={ this.onCheckboxPress }
                        onGlobalCheckboxPress={ this.onGlobalCheckboxPress }
                        amount={ amount }
                        amountColumn={ 5 }
                    />
                </div>
            </Fragment>
        );
    };

    renderTable = (advisor, amount) => {
        return (<Fragment>
            { this.renderAdvisorPendingPaymendsDetails(advisor) }
            { this.renderAdvisorPendingPaymends(advisor, amount) }
        </Fragment>);
    };

    renderPendingPayments = () => {

        const {advisorsPendingPayments} = this.props;

        return <Table
            columns={ [strings.ID, strings.name, strings.email, strings.notPaidRequests, strings.totalProfit, strings.action] }
            data={ appUtils.parseAdvisorsPendingPayments(advisorsPendingPayments.data, this.renderTable) }
        />;
    };

    render() {

        const {advisorsPendingPayments} = this.props;

        if (!advisorsPendingPayments) {
            return (<Fragment/>);
        }

        return (
            <div className="page-container flex-column flex-fill">
                <TopicSection
                    title={ strings.pendingPayments }
                    buttons={ [
                        {
                            title: strings.exportAllTransactions,
                            action: () => {
                                this.props.exportData("transactions");
                            }
                        }
                    ] }
                />

                { this.renderPendingPayments() }
                { this.renderPagination() }
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        advisorsPendingPayments: state.advisorsPayments.advisorsPendingPayments,
        selectedRequests: state.advisorsPayments.selectedRequests,
        page: state.pagination[paginationTypes.PENDING_PAYMENTS]
    };
};

export default connect(mapStateToProps, {
    getAdvisorsPendingPayments,
    selectPendingPaymentRequest,
    updatePendingPaymentRequests,
    setPagination,
    exportData
})(PendingPaymentsPage);