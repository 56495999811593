import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { strings } from "../../../resources/strings";
import Section from "../../common/Section";
import { Fields } from "../../common/Fields";
import FormButton from "../../common/buttons/FormButton";
import { reduxForm } from "redux-form";
import { formUtils } from "../../../utils/formUtils";
import { changePassword } from "../../../actions";

class ChangePassword extends Component {

    onFormSubmit = (formValues) => {
        this.props.changePassword(formValues, this.props.admin.id);
    };

    render() {
        return (
            <Fragment>
                <Section title={ strings.changePassword } first/>
                <form
                    onSubmit={ this.props.handleSubmit(this.onFormSubmit) }
                >
                    { Fields.oldPassword }
                    { Fields.password }
                    { Fields.repeatPassword }
                    <div className="col-4 p-0">
                        <FormButton
                            onPress={ this.onFormSubmit }
                            className="margin-top-m"
                            title={ strings.edit }
                        />
                    </div>
                </form>
            </Fragment>
        );
    }
}

const passwordForm = reduxForm({form: "passwordForm", validate: formUtils.validate})(ChangePassword);
const mapStateToProps = state => {
    return {admin: state.admin.myAccount};
};

export default connect(mapStateToProps, {changePassword})(passwordForm);