import React from "react";
import { connect } from "react-redux";
import { appUtils } from "../../utils/appUtils";
import { strings } from "../../resources/strings";

class Page404 extends React.Component {

    render() {

        const style = appUtils.isEpmtyObject(this.props.admin) ? "page-404-logout page-404" : "page-404";

        return (<div className={ style }>
            <h1>{strings.code404}</h1>
            <hr/>
            <h3>{strings.pageNotFound}</h3>
        </div>);
    }
}

const mapStateToProps = state => {
    return {admin: state.admin.myAccount};
};

export default connect(mapStateToProps)(Page404);