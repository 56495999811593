import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import TopicSection from "../../common/TopicSection";
import { strings } from "../../../resources/strings";
import Details from "../../common/Details";
import {
    getConflict,
    hideDialogLarge,
    showDialog,
    showDialogLarge,
    typeComment,
    updateConflict
} from "../../../actions";
import Detail from "../../common/Detail";
import { appUtils } from "../../../utils/appUtils";
import DefaultButton from "../../common/buttons/DefaultButton";
import navigationUtils from "../../../utils/navigationUtils";
import { pagesPaths } from "../../../constants/pagesPaths";
import Section from "../../common/Section";
import { conflictStatuses, defaultAvatarUrl } from "../../../constants/app";
import Contacts from "../../common/Contacts";

class ConflictPreviewPage extends Component {

    componentDidMount() {
        this.props.getConflict(this.props.match.params.id);
    }

    renderUser = user => {

        if (!user) {
            return <span class="text-danger">{ strings.deleted }</span>;
        }

        const imageUrl = user.user.image ? appUtils.getFilePath(user.user.image.url) : defaultAvatarUrl;
        const userName = user.user.firstName + " " + user.user.lastName;
        const type = appUtils.parseUserType(user.user.userType);
        const location = user.location ? strings[user.location] : null;
        const renderLocation = location ? <span>{ strings.location + ": " + location }</span> : null;

        return (
            <div className="conflict-user-container row flex-wrap align-items-start">
                <div className="col-6 col-sm-12 col-lg-6 col-md-12">
                    <img className="avatar-user-preview"
                         src={ imageUrl }
                         alt={ user.user.firstName }/>
                    <span className="bold">{ userName }</span>
                    <span>{ strings.type + ": " + type }</span>
                    <span>{ strings.email + ": " + user.user.email }</span>
                    { renderLocation }
                </div>
                <div className="col-6 contacts-container">
                    <Contacts contacts={ user.user.contacts }/>
                </div>
            </div>
        );
    };

    renderCommentField = () => {
        return (
            <div className="comment-container">
                <label className="input-label">{ strings.writeComment }</label>
                <textarea className="form-control white-input-field" type="text" onChange={ (e) => {
                    this.props.typeComment(e.target.value);
                } }/>
            </div>
        );
    };

    renderDetails = () => {

        const {conflict} = this.props;

        const firstColumn = (
            <Fragment>
                <Detail title={ strings.ID } value={ conflict.id }/>
                <Detail title={ strings.description } value={ conflict.text }/>
            </Fragment>
        );

        const secondColumn = (
            <Fragment>
                <Detail title={ strings.status } value={ appUtils.parseStatus(conflict.status) }/>
                <Detail title={ strings.date } value={ appUtils.parseDateTime(conflict.createdAt) }/>
            </Fragment>
        );

        const detailsStyle = conflict.comment ? "col-9 m-0" : "col-12 m-0";

        return (
            <div className={ detailsStyle }>
                <Details>
                    <div className="col-6">
                        { firstColumn }
                    </div>
                    <div className="col-6">
                        { secondColumn }
                    </div>
                    <div className="row description-button-container">
                        <DefaultButton title={ strings.viewRequest } onPress={ () => {
                            navigationUtils.navigate(pagesPaths.REQUESTS + "/" + conflict.request.id);
                        } }/>
                    </div>
                </Details>
            </div>
        );
    };

    onResolvePress = () => {

        const {conflict} = this.props;

        this.props.showDialogLarge(strings.resolveConflict, this.renderCommentField(),
            {
                title: strings.send, action: () => {
                    if (!this.props.conflict.commentField) {
                        this.props.showDialog(strings.error, strings.noCommentMessage, {
                            title: strings.ok,
                            close: true
                        });
                        return;
                    }
                    this.props.updateConflict(conflict.id, conflictStatuses.RESOLVED, this.props.conflict.commentField);
                    this.props.typeComment("");
                }
            },
            {
                title: strings.cancel, danger: true, action: () => {
                    this.props.hideDialogLarge();
                    this.props.typeComment("");
                }
            }
        );
    };

    onClosePress = () => {

        const {conflict} = this.props;

        this.props.showDialogLarge(strings.closeConflict, this.renderCommentField(),
            {
                title: strings.send, action: () => {
                    if (!this.props.conflict.commentField) {
                        this.props.showDialog(strings.error, strings.noCommentMessage, {
                            title: strings.ok,
                            close: true
                        });
                        return;
                    }
                    this.props.updateConflict(conflict.id, conflictStatuses.CLOSED, this.props.conflict.commentField);
                    this.props.typeComment("");
                }
            },
            {
                title: strings.cancel, danger: true, action: () => {
                    this.props.hideDialogLarge();
                    this.props.typeComment("");
                }
            }
        );
    };

    renderComments = () => {

        const {comment} = this.props.conflict;

        if (comment) {
            return (
                <div className="col-3">
                    <Section first title={ strings.comment }/>
                    <p className="comment">{ comment }</p>
                </div>
            );
        }
    };

    render() {

        const {conflict} = this.props;

        if (appUtils.isEpmtyObject(conflict)) {
            return (<Fragment/>);
        }

        const {client, translator} = conflict.request;

        let initiator = null;
        let against = null;

        if (client && client.userId === conflict.userId) {
            initiator = client;
            against = translator;
        }

        if (translator && translator.userId === conflict.userId) {
            initiator = translator;
            against = client;
        }

        const pageTitle = "Conflict #" + conflict.id;

        return (
            <div className="page-container flex-column flex-fill">
                <TopicSection
                    title={ pageTitle }
                    buttons={ [{
                        title: strings.resolve, danger: false, action: this.onResolvePress
                    },
                        {
                            title: strings.close, danger: true, action: this.onClosePress
                        }] }
                />
                <div className="row">
                    { this.renderDetails() }
                    { this.renderComments() }
                </div>
                <div className="row p-0 m-0">
                    <div className="col-6 pl-0">
                        <Section title={ strings.initiator }/>
                        { this.renderUser(initiator) }
                    </div>
                    <div className="col-6 pr-0">
                        <Section title={ strings.against }/>
                        { this.renderUser(against) }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {conflict: state.conflicts.currentConflict};
};

export default connect(mapStateToProps, {
    getConflict,
    showDialogLarge,
    typeComment,
    hideDialogLarge,
    updateConflict,
    showDialog
})(ConflictPreviewPage);
