import { actions } from "../constants/actions";

export const showDialog = (title, message, primaryButton, secondaryButton) => {
    return {type: actions.SHOW_DIALOG, payload: {title, message, primaryButton, secondaryButton}};
};

export const hideDialog = () => {
    return {type: actions.HIDE_DIALOG, payload: false};
};

export const showDialogLarge = (title, view, primaryButton, secondaryButton) => {
    return {type: actions.SHOW_DIALOG_LARGE, payload: {title, view, primaryButton, secondaryButton}};
};

export const hideDialogLarge = () => {
    return {type: actions.HIDE_DIALOG_LARGE, payload: false};
};