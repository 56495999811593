import React, { Component, Fragment } from "react";
import Navigator from "./Navigator";
import DefaultButton from "./buttons/DefaultButton";

class TopicSection extends Component {

    renderButtons = () => {

        const {buttons} = this.props;

        if (!buttons) {
            return;
        }

        return this.props.buttons.map((button, index) => {

            if (!button) {
                return;
            }

            const buttonStyle = index > 0 ? "details-button col-4" : "col-4";
            const danger = button.danger;

            return (
                <DefaultButton danger={ danger } key={ "db" + index } invert={ button.invert } className={ buttonStyle }
                               title={ button.title }
                               onPress={ button.action }/>
            );
        });
    };

    render() {
        return (
            <Fragment>
                <Navigator location={ this.props.location }/>
                <div className="row m-0">
                    <div className="col-4 p-0">
                        <h1>{ this.props.title }</h1>
                    </div>
                    <div className="col-8 p-0">
                        <div className="row justify-content-end flex-fill details-button-container ml-0 mr-0">
                            { this.renderButtons() }
                        </div>
                        { this.props.children }
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default TopicSection;
