import {
    AccountStatuses,
    adminRights,
    durationStatuses,
    PLATFORM_FEE,
    REQUEST_STATUSES,
    statistics,
    userType
} from "../constants/app";
import moment from "moment";
import { strings } from "../resources/strings";
import navigationUtils from "./navigationUtils";
import { pagesPaths } from "../constants/pagesPaths";
import { BASE_API_URL } from "../constants/paths";
import { languages } from "../resources/languages";
import store from "../store";
import {
    getFile,
    hideDialogLarge,
    markRequestsAsPaid,
    showDialog,
    showDialogLarge,
    signOut,
    updatePendingPaymentRequests
} from "../actions";
import { expertiseUtils } from "./expertiseUtils";
import find from "lodash/find";
import StarRatings from "react-star-ratings";
import React from "react";

export const appUtils = {

    isEpmtyObject: (object) => {

        if (!object) {
            return true;
        }

        return Object.keys(object).length === 0;
    },

    getFee: (amount) => {
        return parseFloat(((amount * PLATFORM_FEE) - amount).toFixed(2));
    },

    getFilePath: path => {
        return BASE_API_URL + "file?filePath=" + path;
    },

    getTotalRequestsMoney: (requests) => {

        let total = 0;

        for (let request of requests) {
            for (let duration of request.durations) {
                if (duration.status === durationStatuses.COMPLETED) {
                    total += duration.price;
                }
            }
        }

        total = (total - appUtils.getFee(total)).toFixed(2);

        return strings.CHF + " " + total;
    },

    errorHandle: (data, isPageResource) => {

        if (data.status === 401) {
            store.dispatch(signOut());
            return;
        }

        if (data.status === 404 && isPageResource) {
            // replace if can
            navigationUtils.navigate(pagesPaths.PAGE_NOT_FOUND);
            return;
        }

        if (data.status >= 400) {

            const message = data.userMessage || strings.somethingWentWrong;

            store.dispatch(showDialog(strings.error, message, {
                title: strings.ok,
                close: true
            }));
        }
    },

    getStatusName: (status) => {

        const statuses = {
            [AccountStatuses.PENDING]: "ACCOUNT_PENDING",
            [AccountStatuses.PUBLISHED]: "ACCOUNT_PUBLISHED",
            [AccountStatuses.IN_REVIEW]: "ACCOUNT_IN_REVIEW",
            [AccountStatuses.REJECTED]: "ACCOUNT_REJECTED"
        };

        return strings[statuses[status]].toUpperCase();
    },

    getAccountStatusTextColor: status => {

        let validStatus = AccountStatuses[status];

        if (validStatus) {
            if (validStatus === AccountStatuses.PUBLISHED) {
                return "green-text";
            }
            if (validStatus === AccountStatuses.PENDING || validStatus === AccountStatuses.IN_REVIEW) {
                return "yellow-text";
            }
            if (validStatus === AccountStatuses.REJECTED) {
                return "red-text";
            }
        }
        return "";
    },

    parseStatus: status => {

        let statusArray = status.split("_");
        let refinedStatus = "";
        statusArray.forEach((name) => {
            refinedStatus += appUtils.parseUpperFirstLetter(name) + " ";
        });
        return refinedStatus;

    },

    parseUpperFirstLetter: (type) => {
        if (!type) {
            return;
        }
        return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
    },

    parseUserType: (type) => {

        let userType = type;

        if (!userType) {
            return;
        }

        userType = strings[type];

        return userType;
    },

    getStatisticStyleDetails: (selectedStatistic) => {
        for (let statistic in statistics) {
            if (statistics[statistic].name === selectedStatistic.description) {
                return statistics[statistic];
            }
        }
    },

    parseDate: (date) => {

        if (!date) {
            return (strings.now);
        }

        return moment(date).format("DD.MM.YYYY");
    },

    parseTime: (date) => {

        if (!date) {
            return (strings.now);
        }

        return moment(date).format("HH:mm");
    },

    parseDateTime: (date) => {

        if (!date) {
            return (strings.now);
        }

        return moment(date).format("DD.MM.YYYY, HH:mm");
    },

    getLanguageFromCode: (code) => {
        for (let language of languages) {
            if (language.code === code) {
                return language.name;
            }
        }
        return null;
    },

    parseLongFileName: (filename) => {
        if (filename.length > 15) {
            let extension = filename.substring(filename.lastIndexOf("."));
            return filename.substring(0, 15) + "..." + extension;
        }

        return filename;
    },

    parseLanguagesFromTo: (languageFrom, languageTo) => {
        return appUtils.getLanguageFromCode(languageFrom) + " > " + appUtils.getLanguageFromCode(languageTo);
    },

    parseTransactionRequests: (requests) => {
        let requestsData = JSON.parse(requests);
        let refinedRequests = "";
        requestsData.forEach((request, index) => {
            if (index === requestsData.length - 1) {
                refinedRequests += request.id;
            } else {
                refinedRequests += request.id + ", ";
            }
        });
        return refinedRequests;
    },

    parseStatusFromNumber: number => {

        const statuses = REQUEST_STATUSES;

        return find(statuses, ["value", number.toString()]).name;

    },

    parseConflicts: (conflicts) => {

        let refinedConflicts = [];

        for (let index in conflicts) {
            const conflict = conflicts[index];
            const user = conflict.user;
            const name = user ? user.firstName + " " + user.lastName : strings.deleted;
            const type = user ? appUtils.parseUpperFirstLetter(user.userType) : strings.deleted;
            const date = appUtils.parseDate(conflict.createdAt);
            const status = conflict.status.charAt(0).toUpperCase() + conflict.status.slice(1).toLowerCase();
            const currentConflict = [conflict.id, conflict.requestId, name, type, status, date, [{
                type: "view",
                action: () => {
                    navigationUtils.navigate(pagesPaths.CONFLICTS + "/" + conflict.id);
                }
            }]];
            refinedConflicts.push(currentConflict);
        }
        return refinedConflicts;
    },

    parseDocuments: (documents) => {

        let refinedDocuments = [];

        for (let index in documents) {
            const document = documents[index];
            const name = appUtils.parseLongFileName(document.name);
            const type = strings[document.type];
            const date = appUtils.parseDate(document.createdAt);

            const currentDocument = [name, type, date, [{
                type: "download",
                action: () => {
                    store.dispatch(getFile(document.path));
                }
            }]];
            refinedDocuments.push(currentDocument);
        }
        return refinedDocuments;
    },

    parseLanguages: (languages, renderCertificates) => {

        let refinedLanguages = [];

        for (let index in languages) {

            const language = languages[index];
            const name = appUtils.getLanguageFromCode(language.languageCode);
            const certificates = renderCertificates(language.certificates);

            const currentLanguage = [name, [{
                type: "view",
                action: () => {
                    store.dispatch(showDialogLarge(strings.certificates, certificates,
                        {
                            title: strings.close, close: true, action: () => {
                            }
                        }
                        )
                    );
                }
            }]];
            refinedLanguages.push(currentLanguage);
        }
        return refinedLanguages;
    },

    parseCertificates: (certificates) => {
        let refinedCertificates = [];

        for (let index in certificates) {
            const certificate = certificates[index];
            const name = certificate.name;
            const id = certificate.id;
            const date = appUtils.parseDate(certificate.createdAt);
            const currentCertificate = [id, name, date, [{
                type: "download",
                action: () => {
                    store.dispatch(getFile(certificate.path));
                }
            }]];
            refinedCertificates.push(currentCertificate);
        }
        return refinedCertificates;
    },

    parseUsers: (users) => {

        let refinedUsers = [];

        for (let index in users) {
            const user = users[index];
            const name = user.firstName + " " + user.lastName;
            const type = appUtils.parseUpperFirstLetter(user.userType);
            let completedRequest = user[type.toLowerCase()] && user[type.toLowerCase()].countCompletedRequests ? user[type.toLowerCase()].countCompletedRequests.count : null;
            completedRequest = completedRequest ? completedRequest : 0;
            const userType = appUtils.parseUserType(user.userType) ? appUtils.parseUserType(user.userType) : strings.notSet;
            const currentUser = [user.id, name, userType, user.email, completedRequest, [{
                type: "view",
                action: () => {
                    navigationUtils.navigate(pagesPaths.USERS + "/" + user.id);
                }
            }]];
            refinedUsers.push(currentUser);
        }
        return refinedUsers;
    },

    parseTransactions: (transactions) => {
        let refinedTransactions = [];

        for (let index in transactions) {
            const transaction = transactions[index];
            const requests = appUtils.parseTransactionRequests(transaction.forRequests);
            const IBAN = transaction.toBankAccount ? transaction.toBankAccount : strings.notSet;
            const IBANHolderName = transaction.bankAccountHolderName ? transaction.bankAccountHolderName : strings.notSet;
            const date = appUtils.parseDate(transaction.createdAt);
            const currentTransaction = [transaction.id, requests, IBAN, IBANHolderName, date];
            refinedTransactions.push(currentTransaction);
        }
        return refinedTransactions;
    },

    parseUserRequests: (requests, correspondentType) => {
        let refinedRequests = [];

        const {resources} = store.getState();

        for (let index in requests) {
            const request = requests[index];
            const languages = appUtils.parseLanguagesFromTo(request.languageFrom, request.languageTo);
            const correspondent = request[correspondentType.toLowerCase()] ? request[correspondentType.toLowerCase()].user : null;
            const correspondentName = correspondent ? correspondent.firstName + " " + correspondent.lastName : strings.deleted;
            const status = request.clientImportance ? appUtils.parseStatusFromNumber(request.clientImportance) : strings.notSet;
            const date = appUtils.parseDate(request.createdAt);
            const expertise = expertiseUtils.getExpertiseNameById(request.expertiseId, resources.expertises);
            const expertiseCategory = expertiseUtils.getExpertiseCategoryNameById(request.expertiseCategoryId, resources.expertiseCategories);
            const currentRequest = [request.id, languages, correspondentName, status, date, expertise, expertiseCategory, [{
                type: "view",
                action: () => {
                    navigationUtils.navigate(pagesPaths.REQUESTS + "/" + request.id);
                }
            }]];
            refinedRequests.push(currentRequest);
        }
        return refinedRequests;
    },

    parseStatistics: (statistics) => {
        return [
            {count: statistics.clients, description: strings.clients},
            {count: statistics.translators, description: strings.advisors},
            {count: statistics.completedrequests, description: strings.completedRequests},
            {count: statistics.totalrequests, description: strings.totalRequests},
            {count: statistics.turnover, description: strings.turnover},
            {count: statistics.profit, description: strings.profit}
        ];
    },

    parseRequests: (requests) => {

        let refinedRequests = [];

        for (let index in requests) {
            const request = requests[index];
            const languages = appUtils.parseLanguagesFromTo(request.languageFrom, request.languageTo);
            const status = request.clientImportance ? appUtils.parseStatusFromNumber(request.clientImportance) : strings.notSet;
            const date = appUtils.parseDate(request.createdAt);
            const client = request[userType.CLIENT.toLowerCase()] ? request[userType.CLIENT.toLowerCase()].user.firstName + " " + request[userType.CLIENT.toLowerCase()].user.lastName : strings.deleted;
            const advisor = request[userType.ADVISOR.toLowerCase()] ? request[userType.ADVISOR.toLowerCase()].user.firstName + " " + request[userType.ADVISOR.toLowerCase()].user.lastName : strings.deleted;
            const currentRequest = [request.id, client, advisor, languages, date, status, [{
                type: "view",
                action: () => {
                    navigationUtils.navigate(pagesPaths.REQUESTS + "/" + request.id);
                }
            }]];
            refinedRequests.push(currentRequest);
        }
        return refinedRequests;
    },

    parseDurations: (durations) => {

        let refinedDurations = [];

        for (let index in durations) {
            const duration = durations[index];
            const status = appUtils.parseStatus(duration.status);
            const startTime = appUtils.parseTime(duration.createdAt);
            const time = duration.duration + " " + strings.min;
            const extendName = strings.extend + " #" + (+index + 1);
            const currentDuration = [extendName, status, startTime, time];
            refinedDurations.push(currentDuration);
        }
        return refinedDurations;

    },

    parseFeedbacks: (feedbacks, currentRequest) => {

        const request = currentRequest;

        let refinedFeedbacks = [];

        for (let feedback of feedbacks) {

            let comment = strings.notSet;

            if (feedback.comment) {
                comment = <div style={{maxWidth: 400, marginLeft: "auto", marginRight: 0}}>{feedback.comment}</div>
            }

            const ratingStarts = feedback.rating ? <StarRatings
                rating={ parseInt(feedback.rating.stars) }
                starRatedColor="#ea940e"
                numberOfStars={ 5 }
                starDimension="20px"
                starSpacing="0px"
            /> : strings.notSet;

            const user = request[feedback.userType.toLowerCase()] ? request[feedback.userType.toLowerCase()].user : null;

            const currentRequest = [strings[feedback.userType], appUtils.getUserNames(user), feedback.type,ratingStarts , comment];
            refinedFeedbacks.push(currentRequest);
        }

        return refinedFeedbacks;

    },

    getUserNames(user) {
        return user ? user.firstName + " " + user.lastName : strings.deleted;
    },

    parseAdvisorsPendingPayments: (advisors, renderTable) => {
        let refinedAdvisors = [];

        for (let index in advisors) {
            const advisor = advisors[index];
            const name = advisor.user.firstName + " " + advisor.user.lastName;
            const email = advisor.user.email;
            const requests = advisor.requests.length;
            const total = appUtils.getTotalRequestsMoney(advisor.requests);

            const currentAdvisor = [advisor.userId, name, email, requests, total, [{
                type: "payment",
                action: () => {
                    store.dispatch(showDialogLarge(strings.pendingPayment, renderTable(advisor, total),
                        {
                            title: strings.markRequestsAsPaid, action: () => {

                                const selectedRequests = store.getState().advisorsPayments.selectedRequests;
                                const advisorId = advisor.requests[0] ? advisor.requests[0].translatorId : null;

                                if (selectedRequests.length === 0) {
                                    store.dispatch(showDialog(strings.error, strings.noRequestsSelectedMessage, {
                                        title: strings.ok,
                                        close: true
                                    }));
                                    return;
                                }

                                if (!advisor.bankAccount) {
                                    store.dispatch(showDialog(strings.error, strings.noBankAccountMessage, {
                                        title: strings.ok,
                                        close: true
                                    }));
                                    return;
                                }

                                store.dispatch(markRequestsAsPaid(selectedRequests, advisorId, advisor.userId));
                            }
                        }, {
                            title: strings.cancel, danger: true, action: () => {
                                store.dispatch(updatePendingPaymentRequests([]));
                                store.dispatch(hideDialogLarge());
                            }
                        }
                        )
                    );
                }
            },
                {
                    type: "view",
                    action: () => {
                        navigationUtils.navigate(pagesPaths.USERS + "/" + advisor.userId);
                    }
                }]];
            refinedAdvisors.push(currentAdvisor);
        }
        return refinedAdvisors;
    },

    parsePendingPaymentsRequests: (requests) => {

        let refinedRequests = [];

        for (let index in requests) {
            const request = requests[index];
            const client = request[userType.CLIENT.toLowerCase()];
            const clientName = client.user.firstName + " " + client.user.lastName;
            const startDate = appUtils.parseDateTime(request.durations[0].startTime);
            const advisorProfit = appUtils.getTotalRequestsMoney([{...request}]);

            const currentRequest = [request.id, clientName, startDate, advisorProfit, [{
                type: "view",
                action: () => {
                    store.dispatch(hideDialogLarge());
                    navigationUtils.navigate(pagesPaths.REQUESTS + "/" + request.id);
                }
            }]];
            refinedRequests.push(currentRequest);
        }
        return refinedRequests;
    },

    parseAdmins: (admins) => {

        let refinedAdmins = [];

        for (let index in admins) {
            const admin = admins[index];
            const name = admin.firstName + " " + admin.lastName;
            const lastLogin = admin.lastLogin ? appUtils.parseDateTime(admin.lastLogin) : strings.noLogsYet;
            const action = admin.rights !== adminRights.MASTER ? {
                type: "view",
                action: () => {
                    navigationUtils.navigate(pagesPaths.SETTINGS + "/" + admin.id);
                }
            } : {
                type: "view",
                disabled: true
            };

            const rights = appUtils.parseUpperFirstLetter(admin.rights);

            const myAccount = [admin.id, name, admin.email, lastLogin, rights, [action]];
            refinedAdmins.push(myAccount);
        }
        return refinedAdmins;
    },

    parseUncompletedPaidRequests: (requests) => {

        let refinedRequests = [];
        const {currentUser} = store.getState().users;

        for (let index in requests) {
            const request = requests[index];
            const correspondentName = currentUser.userType === userType.CLIENT ?
                request[userType.ADVISOR.toLowerCase()].user.firstName + " " + request[userType.ADVISOR.toLowerCase()].user.lastName :
                request[userType.CLIENT.toLowerCase()].user.firstName + " " + request[userType.CLIENT.toLowerCase()].user.lastName;
            const price = strings.CHF + " " + request.durations[0].price;
            const action = [{
                type: "view",
                action: () => {
                    store.dispatch(hideDialogLarge());
                    navigationUtils.navigate(pagesPaths.REQUESTS + "/" + request.id);
                }
            }];

            refinedRequests.push([request.id, correspondentName, price, action]);
        }
        return refinedRequests;
    }

};
