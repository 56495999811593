import { actions } from "../constants/actions";
import lodash from "lodash";

const initState = {advisorsPendingPayments: {}, selectedRequests: []};

const advisorsPaymentsReducer = (state = {...initState}, action) => {

    if (action.type === actions.GET_ADVISORS_PENDING_PAYMENTS) {
        state.advisorsPendingPayments = action.payload;
        for (let advisor of state.advisorsPendingPayments.data) {
            for (let request of advisor.requests) {
                request.durations[0].startTime = +request.durations[0].startTime;
            }
        }

        state.advisorsPendingPayments = {...state.advisorsPendingPayments};

        return {...state};
    }

    if (action.type === actions.SELECT_PENDING_PAYMENT_REQUEST) {
        const id = action.payload;
        if (state.selectedRequests.includes(id)) {
            lodash.remove(state.selectedRequests, function (el) {
                return el === id;
            });
        } else {
            state.selectedRequests.push(action.payload);
        }
        state.selectedRequests = [...state.selectedRequests];

        return {...state};
    }

    if (action.type === actions.UPDATE_PENDING_PAYMENT_REQUESTS) {
        state.selectedRequests = action.payload;
        state.selectedRequests = [...state.selectedRequests];

        return {...state};
    }

    if (action.type === actions.MARK_REQUESTS_AS_PAID) {
        state.selectedRequests = [];
        state.selectedRequests = [...state.selectedRequests];
        const currentAdvisor = lodash.find(state.advisorsPendingPayments.data, function (advisor) {
            return advisor.userId === action.payload.userId;
        });

        for (let request of action.payload.requests) {
            lodash.remove(currentAdvisor.requests, function (el) {
                return el.id === request;
            });
        }

        state.advisorsPendingPayments = {...state.advisorsPendingPayments};

        return {...state};
    }

    return state;
};

export default advisorsPaymentsReducer;