import { actions } from "../constants/actions";

const initState = {};

const resourceReducer = (state = initState, action) => {

    if (action.type === actions.GET_EXPERTISES) {
        state.expertises = action.payload;
        return {...state};
    }

    if (action.type === actions.GET_EXPERTISE_CATEGORIES) {
        state.expertiseCategories = action.payload;
        return {...state};
    }

    return state;
};

export default resourceReducer;