import React from "react";
import { strings } from "../resources/strings";
import { changeAccountStatus } from "../actions";
import store from "../store";

export const formUtils = {
    validate: formValues => {
        const errors = {};
        const emailReg = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!emailReg.test(String(formValues.email).toLowerCase())) {
            errors.email = strings.emailErrorMessage;
        }
        if (formValues.emailFilter && !emailReg.test(String(formValues.emailFilter).toLowerCase())) {
            errors.emailFilter = strings.emailErrorMessage;
        }
        if (!(formValues.password && formValues.password.length > 7)) {
            errors.password = strings.passwordErrorMessage;
        }
        if (!(formValues.oldPassword && formValues.oldPassword.length > 7)) {
            errors.oldPassword = strings.passwordErrorMessage;
        }
        if (formValues.password !== formValues.repeatPassword) {
            errors.repeatPassword = strings.passwordsMismatch;
        }
        if (!formValues.rejectReason) {
            errors.rejectReason = strings.rejectReasonError;
        }
        return errors;
    },
    renderErrorMessage: meta => {

        if (meta.error && meta.touched) {
            return <span className="text-danger">{ meta.error }</span>;
        }
    },
    getFieldStyle: meta => {

        let className = "form-control form-field white-input-field";

        if (meta.error && meta.touched) {
            className += " alert-danger";
        }

        return className;
    },
    remoteSubmits: {
        accountStatusSubmit: (formValues) => {
            const currentUser = store.getState().users.currentUser;
            store.dispatch(changeAccountStatus(formValues, currentUser.translator.id));
        }
    }
};
