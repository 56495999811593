export const actions = {
    SHOW_GLOBAL_LOADER: "SHOW_GLOBAL_LOADER",
    HIDE_GLOBAL_LOADER: "HIDE_GLOBAL_LOADER",
    SIGN_IN: "SIGN_IN",
    SHOW_DIALOG: "SHOW_DIALOG",
    HIDE_DIALOG: "HIDE_DIALOG",
    LOAD_ADMIN: "LOAD_ADMIN",
    SIGN_OUT: "SIGN_OUT",
    GET_CONFLICTS: "GET_CONFLICTS",
    GET_STATISTICS: "GET_STATISTICS",
    GET_USERS: "GET_USERS",
    GET_USER_DETAILS: "GET_USER_DETAILS",
    GET_USER_TRANSACTIONS: "GET_USER_TRANSACTIONS",
    GET_USER_REQUESTS: "GET_USER_REQUESTS",
    SHOW_DIALOG_LARGE: "SHOW_DIALOG_LARGE",
    HIDE_DIALOG_LARGE: "HIDE_DIALOG_LARGE",
    GET_EXPERTISES: "GET_EXPERTISES",
    GET_EXPERTISE_CATEGORIES: "GET_EXPERTISE_CATEGORIES",
    DELETE_USER: "DELETE_USER",
    GET_REQUESTS: "GET_REQUESTS",
    GET_LANGUAGES: "GET_LANGUAGES",
    GET_REQUEST: "GET_REQUEST",
    GET_ADVISORS_PENDING_PAYMENTS: "GET_ADVISORS_PENDING_PAYMENTS",
    MARK_REQUESTS_AS_PAID: "MARK_REQUESTS_AS_PAID",
    SELECT_PENDING_PAYMENT_REQUEST: "SELECT_PENDING_PAYMENT_REQUEST",
    UPDATE_PENDING_PAYMENT_REQUESTS: "UPDATE_PENDING_PAYMENT_REQUESTS",
    GET_CONFLICT: "GET_CONFLICT",
    TYPE_COMMENT: "TYPE_COMMENT",
    UPDATE_CONFLICT: "UPDATE_CONFLICT",
    GET_ADMINS: "GET_ADMINS",
    GET_CURRENT_ADMIN: "GET_CURRENT_ADMIN",
    UPDATE_CURRENT_ADMIN: "UPDATE_CURRENT_ADMIN",
    UPDATE_PASSWORD: "UPDATE_PASSWORD",
    BLOCK_UNBLOCK_USER: "BLOCK_UNBLOCK_USER",
    GET_BLOCKED_USERS: "GET_BLOCKED_USERS",
    UPDATE_ACCOUNT_STATUS: "UPDATE_ACCOUNT_STATUS",
    SET_PAGINATION: "SET_PAGINATION",
    CLEAR_PAGINATION: "CLEAR_PAGINATION"
};
