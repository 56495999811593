import React, { Component } from "react";
import { tableActions } from "../../constants/app";
import TableButton from "../common/buttons/TableButton";
import { faEye, faHandHoldingUsd, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faStripe } from "@fortawesome/free-brands-svg-icons";
import { strings } from "../../resources/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import lodash from "lodash";

class Table extends Component {

    state = {checked: []};

    componentDidMount() {
        if (this.props.checkbox) {
            let checkedItems = [];
            for (let row of this.props.data) {
                checkedItems.push(row[0]);
            }
            this.setState({checked: checkedItems});
            this.props.onGlobalCheckboxPress(checkedItems);
        }
    }

    renderCheckbox = (onCheckboxPress, checked) => {

        const check = checked ? <FontAwesomeIcon className="checkbox-icon" icon={ faCheck }/> : null;

        return (
            <div onClick={ onCheckboxPress } className="checkbox">
                { check }
            </div>
        );
    };

    onButtonCheck = id => {
        if (this.state.checked.includes(id)) {
            const checked = [...this.state.checked];
            lodash.remove(checked, function (el) {
                return el === id;
            });
            this.setState({checked});
        } else {
            this.setState({checked: [...this.state.checked, id]});
        }
    };

    renderHeader = () => {

        const columns = this.props.columns;

        return columns.map((column, index) => {

            const tableElementStyle = index === 0 ? "pl-xl-4" : index === columns.length - 1 ? "pr-xl-4" : null;

            return <th className={ tableElementStyle } key={ index + "h" } scope="col">
                { column }
            </th>;
        });
    };

    renderData = () => {

        const data = this.props.data;
        if (this.props.checkbox) {
            return data.map((dataRow, index) => {

                const id = dataRow[0];
                const checked = this.state.checked.includes(id);

                return (<tr key={ index + "r" }>
                    <td>{ this.renderCheckbox(() => {
                        this.props.onCheckboxPress(id);
                        this.onButtonCheck(id);
                    }, checked) }</td>
                    { this.renderRow(dataRow) }
                </tr>);
            });
        }

        return data.map((dataRow, index) => {
            return (<tr key={ index + "r" }>
                { this.renderRow(dataRow) }
            </tr>);
        });

    };

    renderRow = (dataRow) => {

        return dataRow.map((data, index) => {

            const tableElementStyle = index === 0 ? "pl-xl-4" : index === dataRow.length - 1 ? "pr-xl-4" : null;

            if (Array.isArray(data)) {
                return <td className={ tableElementStyle } key={ index + "d" }>
                    { this.renderActionButton(data) }
                </td>;
            } else {
                return <td className={ tableElementStyle } key={ index + "d" }>
                    { data }
                </td>;
            }
        });
    };

    renderActionButton = (data) => {

        const buttons = data.map((action, index) => {

            if (action.type === tableActions.VIEW) {
                return <TableButton
                    key={ index + "bv"}
                    icon={ faEye }
                    onPress={ action.action }
                    tooltip={ strings.view }
                    disabled={ action.disabled }
                />;
            }

            if (action.type === tableActions.PAYMENT) {
                return <TableButton
                    key={ index + "bp" }
                    icon={ faHandHoldingUsd }
                    onPress={ action.action }
                    tooltip={ strings.chooseRequests }
                />;
            }

            if (action.type === tableActions.STRIPE) {
                return <TableButton
                    key={ index + "bs" }
                    icon={ faStripe }
                    onPress={ action.action }
                    tooltip={ strings.paymentDetails }
                />;
            }

            if (action.type === tableActions.DOWNLOAD) {
                return <TableButton
                    key={ index + "dld" }
                    icon={ faDownload }
                    onPress={ action.action }
                    tooltip={ strings.download }
                />;
            }

            return <TableButton
                key={ index + "b" }
                icon={ faHandHoldingUsd }
                onPress={ action.action }
                tooltip={ strings.chooseRequests }
            />;

        });

        return <div className="table-buttons-container">{ buttons }</div>;
    };

    renderNoItemsMessage = () => {
        const noItemsMessage = this.props.noItemsMessage ? this.props.noItemsMessage : strings.noItems;
        const colSpan = this.props.checkbox ? this.props.columns.length + 1 : this.props.columns.length;

        if (this.props.data.length === 0) {
            return <tr className="d-table-row">
                <td colSpan={ colSpan } className="text-center">
                    { noItemsMessage }
                </td>
            </tr>;
        }
    };

    renderGlobalCheckbox = () => {
        if (this.props.checkbox) {
            let checkedItems = [];
            for (let row of this.props.data) {
                checkedItems.push(row[0]);
            }
            const onCheckboxPress = () => {
                if (this.state.checked.length !== this.props.data.length) {
                    this.setState({checked: checkedItems});
                    this.props.onGlobalCheckboxPress(checkedItems);
                } else {
                    this.props.onGlobalCheckboxPress([]);
                    this.setState({checked: []});
                }
            };
            const checked = this.state.checked.length === this.props.data.length;
            return (<th>{ this.renderCheckbox(onCheckboxPress, checked) }</th>);
        }
    };

    renderTotal = () => {

        const {amount, amountColumn} = this.props;
        const colSpanText = this.props.checkbox ? amountColumn - 1 : amountColumn;
        const columnsLeft = this.props.checkbox ? this.props.columns.length - colSpanText + 1 : this.props.columns.length - colSpanText;

        let valueStyle, titleStyle;

        if (!this.props.checkbox) {
            titleStyle = "pl-xl-4";
        }

        if (!this.props.checkbox && this.props.columns.length === colSpanText + 1) {
            valueStyle = "total-last-column pr-xl-4";
        }

        if (this.props.checkbox && this.props.columns.length === colSpanText) {
            valueStyle = "total-last-column";
        }

        if (amount && amountColumn) {
            return (<tr className="total">
                    <td className={ titleStyle } colSpan={ colSpanText }>
                        { strings.total }
                    </td>
                    <td className={ valueStyle } colSpan={ columnsLeft }>
                        { amount }
                    </td>
                </tr>
            );
        }
    };

    render() {

        return (
            <div className="table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        { this.renderGlobalCheckbox() }
                        { this.renderHeader() }
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderData() }
                    { this.renderNoItemsMessage() }
                    { this.renderTotal() }
                    </tbody>
                </table>
            </div>
        );
    }

}

export default Table;
