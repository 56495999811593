import { actions } from "../constants/actions";

const initState = {currentUser: null, users: {}, blockedUsers: []};

const userReducer = (state = initState, action) => {

    if (action.type === actions.GET_USERS) {
        state.users = {...action.payload};
        state.currentUser = {};
        state.currentUser = {...state.currentUser};
        return {...state};
    }

    if (action.type === actions.GET_BLOCKED_USERS) {
        state.blockedUsers = [...action.payload];
        state.currentUser = {};
        state.currentUser = {...state.currentUser};
        return {...state};
    }

    if (action.type === actions.GET_USER_DETAILS) {
        state.currentUser = {...action.payload};
        return {...state};
    }

    if (action.type === actions.GET_USER_TRANSACTIONS) {
        state.currentUser.transactions = {...action.payload};
        state.currentUser = {...state.currentUser};
        return {...state};
    }

    if (action.type === actions.GET_USER_REQUESTS) {
        state.currentUser.requests = {...action.payload};
        state.currentUser = {...state.currentUser};
        return {...state};
    }

    if (action.type === actions.BLOCK_UNBLOCK_USER) {
        state.currentUser.isBlocked = !state.currentUser.isBlocked;
        for (let request of state.currentUser.requests.data) {
            if (request.clientImportance === 4 || request.clientImportance === 1 || request.clientImportance === 3) {
                request.clientImportance = 7;
            }
        }
        state.currentUser = {...state.currentUser};
        return {...state};
    }

    if (action.type === actions.UPDATE_ACCOUNT_STATUS) {
        state.currentUser.translator.accountStatus = {...action.payload};
        state.currentUser.translator = {...state.currentUser.translator};
        state.currentUser = {...state.currentUser};
        return {...state};
    }

    if (action.type === actions.DELETE_USER) {

        return {...state};
    }

    return state;
};

export default userReducer;
