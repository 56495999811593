import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { strings } from "../../../resources/strings";
import Section from "../../common/Section";
import { Fields } from "../../common/Fields";
import FormButton from "../../common/buttons/FormButton";
import { reduxForm } from "redux-form";
import { formUtils } from "../../../utils/formUtils";
import { editPersonalDetails } from "../../../actions";

class PersonalDetails extends Component {

    onFormSubmit = (formValues) => {
        this.props.editPersonalDetails(formValues, this.props.admin.id);
    };

    render() {
        return (
            <Fragment>
                <Section title={ strings.personalDetails } first/>
                <form
                    onSubmit={ this.props.handleSubmit(this.onFormSubmit) }
                >
                    { Fields.firstName }
                    { Fields.lastName }
                    { Fields.emailDisabled }
                    <div className="col-4 p-0">
                        <FormButton
                            onPress={ this.onFormSubmit }
                            className="margin-top-m"
                            title={ strings.edit }
                        />
                    </div>
                </form>
            </Fragment>
        );
    }
}

const personalDetailsForm = reduxForm({form: "personalDetailsForm", validate: formUtils.validate,destroyOnUnmount: false, enableReinitialize: true})(PersonalDetails);
const mapStateToProps = state => {
    return {
        admin: state.admin.currentAdmin,
        initialValues: {...state.admin.currentAdmin, emailDisabled: state.admin.currentAdmin.email}
    };
};

export default connect(mapStateToProps, {editPersonalDetails})(personalDetailsForm);



