import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
    blockUnblockUser,
    changeAccountStatus,
    deleteUser,
    getUserDetails,
    getUserUncompletedPaidRequests,
    showDialog,
    showDialogLarge,
    getFile
} from "../../../actions";
import { strings } from "../../../resources/strings";
import Section from "../../common/Section";
import { appUtils } from "../../../utils/appUtils";
import Detail from "../../common/Detail";
import Contacts from "../../common/Contacts";
import {
    AccountStatuses,
    ADVISOR_STATUS_TYPES,
    ADVISOR_STATUTES,
    defaultAvatarUrl,
    userTypes
} from "../../../constants/app";
import Details from "../../common/Details";
import Table from "../../common/Table";
import Transactions from "./Transactions";
import Requests from "./Requests";
import TopicSection from "../../common/TopicSection";
import { expertiseUtils } from "../../../utils/expertiseUtils";
import { countries } from "../../../resources/countries";
import AccountStatusForm from "../../common/AccountStatusForm";
import { submit } from "redux-form";

class UserPreviewPage extends Component {

    componentDidMount() {
        this.props.getUserDetails(this.props.match.params.id);
    }

    renderFirstDetailColumn = () => {

        const {currentUser} = this.props;
        const name = currentUser.firstName + " " + currentUser.lastName;

        const details = [
            {title: strings.userId, value: currentUser.id},
            {title: strings.name, value: name},
            {title: strings.email, value: currentUser.email}
        ];

        return details.map((detail) => {
            const value = detail.value ? detail.value : strings.notSet;
            return <Detail key={ detail.title } title={ detail.title } value={ value }/>;
        });
    };

    renderSecondColumn = () => {
        const {currentUser} = this.props;
        const type = appUtils.parseUpperFirstLetter(currentUser.userType);
        const expat = currentUser.expat ? strings.yes : strings.no;

        const subUser = currentUser[type.toLowerCase()];
        const userType = appUtils.parseUserType(currentUser.userType);

        const details = [
            {title: strings.type, value: userType},
            {title: strings.registeredOn, value: appUtils.parseDateTime(currentUser.createdAt)}
        ];

        if (subUser) {
            details.push({title: strings.expat, value: expat});
        }

        return details.map((detail) => {
            const value = detail.value ? detail.value : strings.notSet;
            return <Detail key={ detail.title } title={ detail.title } value={ value }/>;
        });
    };

    renderDetails = () => {

        const {currentUser} = this.props;

        if (currentUser.userType === "NOT_SELECTED") {
            return this.renderUserWithoutTypeDetails(currentUser);
        }

        if (currentUser.userType === "CLIENT") {
            return this.renderClientDetails(currentUser);
        }

        if (currentUser.userType === "TRANSLATOR") {
            return this.renderTranslatorDetails(currentUser);
        }

        return null;

    };

    renderUserAvatar(user) {

        let imageUrl = defaultAvatarUrl;

        if (user != null && user.image) {
            imageUrl = appUtils.getFilePath(user.image.url);
        }

        return (<img className="avatar-user-preview"
                     src={ imageUrl }
                     alt={ user.firstName }/>);
    }

    renderUserWithoutTypeDetails(user) {

        return (
            <Details className="align-items-center">
                <div className="col-3 align-self-center justify-content-center d-flex">
                    { this.renderUserAvatar(user) }
                </div>
                <div className="col-3">
                    { this.renderFirstDetailColumn() }
                </div>
                <div className="col-3">
                    { this.renderSecondColumn() }
                </div>
                <div className="col-3">
                </div>
            </Details>
        );

    }

    renderClientDetails(user) {
        return (
            <Details className="align-items-center">
                <div className="col-3 align-self-center justify-content-center d-flex">
                    { this.renderUserAvatar(user) }
                </div>
                <div className="col-3">
                    { this.renderFirstDetailColumn() }
                </div>
                <div className="col-3">
                    { this.renderSecondColumn() }
                </div>
                <div className="col-3">
                    <Contacts contacts={ user.contacts }/>
                </div>
            </Details>
        );
    }

    renderTranslatorDetails(user) {
        return (
            <Details className="align-items-center">
                <div className="col-2 align-self-center justify-content-center d-flex">
                    { this.renderUserAvatar(user) }
                </div>
                <div className="col-2">
                    { this.renderTranslatorFirstDetailColumn(user) }
                </div>
                <div className="col-3">
                    { this.renderTranslatorSecondDetailColumn(user) }
                </div>
                <div className="col-3">
                    { this.renderTranslatorThirdDetailColumn(user) }
                </div>
                <div className="col-2">
                    <Contacts contacts={ user.contacts }/>
                </div>
            </Details>
        );
    }

    renderTranslatorFirstDetailColumn(user) {

        const {translator} = user;
        const name = user.firstName + " " + user.lastName;
        const status = <strong
            className={ appUtils.getAccountStatusTextColor(translator.accountStatus.status) + " status" }>{ appUtils.getStatusName(translator.accountStatus.status) }</strong>;

        const details = [
            {title: strings.userId, value: user.id},
            {title: strings.accountStatus, value: status}
        ];

        if (translator.accountStatus.status === AccountStatuses.REJECTED) {
            details.push({title: strings.rejectReason, value: translator.accountStatus.rejectReason || strings.notSet});
        }

        details.push({title: strings.name, value: name});
        details.push({title: strings.email, value: user.email});

        return details.map((detail) => {
            if (!detail.value) {
                return strings.notSet;
            }
            return <Detail key={ detail.title } title={ detail.title } value={ detail.value }/>;
        });
    };

    renderTranslatorSecondDetailColumn(user) {

        const {translator} = user;

        const views = [];

        const details = [
            {
                title: strings.type,
                value: strings.advisor
            },
            {
                title: strings.registeredOn,
                value: appUtils.parseDateTime(user.createdAt)
            },
            {
                title: strings.IBAN,
                value: translator.bankAccount
            },
            {
                title: strings.IBANHolderName,
                value: translator.bankAccountHolderName
            }

        ];

        for (let detail of details) {
            views.push(this.renderDetail(detail));
        }

        return views;
    };

    renderTranslatorThirdDetailColumn(user) {

        const {translator} = user;
        const expat = user.expat ? strings.yes : strings.no;
        const expertise = expertiseUtils.getExpertiseNameById(translator.expertiseRecord ? translator.expertiseRecord.expertiseId : null, this.props.expertises);

        const views = [];

        views.push(
            this.renderDetail({
                title: strings.location,
                value: strings[translator.location]
            })
        );

        views.push(
            this.renderDetail({
                title: strings.expat,
                value: expat
            })
        );

        views.push(
            this.renderDetail({
                title: strings.expertise,
                value: expertise
            })
        );

        views.push(
            this.renderDetail({
                title: strings.about,
                value: translator.aboutMe
            })
        );

        return views;
    };

    renderExpertiseCategories(translator) {

        const {expertiseCategoryRecords} = translator;
        const views = [];

        if (!expertiseCategoryRecords || expertiseCategoryRecords.length === 0) {
            views.push(<p>{ strings.notSet }</p>);
        }

        for (let category of expertiseCategoryRecords) {
            const expertiseCategoryName = expertiseUtils.getExpertiseCategoryNameById(
                category.expertiseCategoryId,
                this.props.expertiseCategories
            );

            views.push(<p key={ category.expertiseCategoryId }>{ expertiseCategoryName }</p>);
        }

        return (
            <div key={ strings.expertiseCategories }>
                <div>
                    <strong>{ strings.expertiseCategories }</strong>
                </div>

                { views }
            </div>
        );

    }

    renderAdvanceDetail = (detailView, isFirst = false) => {
        return <div
            className={ "flex-grow-1 d-flex justify-content-between" + (isFirst ? "" : " mt-1") }>{ detailView }</div>;
    };

    renderDetail(detail) {
        const value = detail.value ? detail.value : strings.notSet;
        return <Detail key={ detail.title } title={ detail.title } value={ value }/>;
    }

    renderAdvisorStatus = (status) => {

        function getFileName(file) {
            const path = file.split("/");
            return path[path.length - 1];
        }

        if (status) {

            const basedInSwitzerland = status.basedInSwish ? strings.yes : strings.no;

            const document = status.documentUrl ? <a className="text-right text-break" target="_blank" rel="noopener noreferrer"
                                                     onClick={()=>{this.props.getFile(status.documentUrl)}}>
                { getFileName(status.documentUrl) } </a> : strings.notSet;
            const statusDetails = [

                {
                    title: strings.status,
                    value: strings[status.type]
                },
                {
                    title: strings.basedInSwitzerland,
                    value: basedInSwitzerland
                }
            ];

            if (!status.basedInSwish) {
                statusDetails.push({
                    title: strings.country,
                    value: countries[status.countryCode].nameEN
                });
            }

            if ((status.type === ADVISOR_STATUS_TYPES.COMPANY || status.type === ADVISOR_STATUS_TYPES.SELF_EMPLOYED)) {
                statusDetails.push({
                    title: strings.document,
                    value: document
                });
            }

            if (status.type === ADVISOR_STATUS_TYPES.EMPLOYEE) {
                statusDetails.push({
                    title: strings.partFullTime,
                    value: status.workLoadPercent
                });
            }

            if (status.type === ADVISOR_STATUS_TYPES.OTHER) {
                statusDetails.push({
                    title: strings.statute,
                    value: strings[status.statute]
                });

                if (status.statute === ADVISOR_STATUTES.OTHER) {
                    statusDetails.push({
                        title: strings.other,
                        value: status.otherNotes
                    });
                }
            }

            return statusDetails.map((statusDetail, index) => {

                const value = typeof statusDetail.value === "string" ?
                    <span className="text-right">{ statusDetail.value }</span> : statusDetail.value;

                const view =
                    <Fragment><strong>{ statusDetail.title }</strong>{ value }</Fragment>;

                return <Fragment key={ "as" + index }>{ this.renderAdvanceDetail(view, index === 0) }</Fragment>;
            });
        } else {
            return <span>{ strings.notSet }</span>;
        }

    };

    renderWorkSchedule = (workDays) => {

        const workSchedule = this.parseWorkSchedule(workDays);

        return workSchedule.map((workDay, index) => {
            return <Fragment key={ "wd" + index }>{ this.renderAdvanceDetail(workDay, index === 0) }</Fragment>;
        });
    };

    renderExpertiseCategories = expertiseCategoryRecords => {

        const views = [];

        if (!expertiseCategoryRecords || expertiseCategoryRecords.length === 0) {
            views.push(<span key={ "needs" }>{ strings.notSet }</span>);
        }

        for (let category in expertiseCategoryRecords) {
            const expertiseCategoryName = expertiseUtils.getExpertiseCategoryNameById(
                expertiseCategoryRecords[category].expertiseCategoryId,
                this.props.expertiseCategories
            );

            views.push(<Fragment key={ expertiseCategoryName }>{
                this.renderAdvanceDetail(<strong>{ expertiseCategoryName }</strong>, category === 0)
            }</Fragment>);
        }

        return views;

    };

    renderAddress = address => {

        if (address) {

            const street = <Fragment key={ "street" }>{ this.renderAdvanceDetail(
                <Fragment><strong>{ strings.street }</strong><span
                    className="text-right">{ address.street }</span>
                </Fragment>, true) }</Fragment>;
            const zipCode = <Fragment key={ "zipCode" }>{ this.renderAdvanceDetail(
                <Fragment><strong>{ strings.zipCode }</strong><span
                    className="text-right">{ address.zipCode }</span>
                </Fragment>) }</Fragment>;
            const city = <Fragment key={ "city" }>{ this.renderAdvanceDetail(
                <Fragment><strong>{ strings.city }</strong><span
                    className="text-right">{ address.city }</span>
                </Fragment>) }</Fragment>;
            const country = <Fragment key={ "country" }>{ this.renderAdvanceDetail(
                <Fragment><strong>{ strings.country }</strong><span
                    className="text-right">{ address.country }</span>
                </Fragment>) }</Fragment>;

            const views = [street, zipCode, city, country];

            return views;
        }

        return <span>{ strings.notSet }</span>;
    };

    renderIsWorkScheduleActive = isActive => {

        if (isActive) {
            return this.renderAdvanceDetail(<Fragment><strong
                className="green-text">{ strings.active }</strong></Fragment>);
        } else {
            return this.renderAdvanceDetail(<Fragment><strong
                className="red-text">{ strings.notActive }</strong></Fragment>);
        }

    };

    renderAdvanceDetails() {

        if (this.props.currentUser.userType === userTypes.NOT_SELECTED || this.props.currentUser.userType === userTypes.CLIENT) {
            return;
        }

        return (
            <div className="row p-0 flex-grow-1 advance-details">
                <div className="col-3">
                    <Section title={ strings.advisorStatus }/>
                    <Details className="flex-column">
                        { this.renderAdvisorStatus(this.props.currentUser.translator.status) }
                    </Details>
                </div>
                <div className="col-2">
                    <Section title={ strings.expertiseCategories }/>
                    <Details className="flex-column">
                        { this.renderExpertiseCategories(this.props.currentUser.translator.expertiseCategoryRecords) }
                    </Details>
                </div>
                <div className="col-3">
                    <Section title={ strings.address }/>
                    <Details className="flex-column">
                        { this.renderAddress(this.props.currentUser.translator.address) }
                    </Details>
                </div>
                <div className="col-4">
                    <Section title={ strings.workSchedule }
                             view={ this.renderIsWorkScheduleActive(this.props.currentUser.translator.isWorkScheduleActive) }/>
                    <Details className="flex-column">
                        { this.renderWorkSchedule(this.props.currentUser.translator.workDays) }
                    </Details>
                </div>
            </div>
        );
    }

    parseWorkSchedule(workDays) {

        const weekDays = [
            strings.MONDAY,
            strings.TUESDAY,
            strings.WEDNESDAY,
            strings.THURSDAY,
            strings.FRIDAY,
            strings.SATURDAY,
            strings.SUNDAY
        ];

        let tempDayIndex = 1;
        let tempTimeSlots;

        const workSchedule = [];

        function parseTime(time) {
            if (time) {
                return time[0] + time[1] + ":" + time[2] + time[3];
            }
        }

        for (let i = 0; i < weekDays.length; i++) {
            tempTimeSlots = "";

            let workDay = workDays.filter(workDay => {
                return workDay.dayOfWeek === tempDayIndex;
            })[0];

            if (workDay) {

                if (workDay.dayOfWeek === tempDayIndex) {

                    if (workDay.timeSlots && workDay.timeSlots.length > 0) {

                        for (let timeSlot of workDay.timeSlots) {
                            tempTimeSlots += parseTime(timeSlot.startTime) + " - " + parseTime(timeSlot.endTime) + " \n";
                        }

                    }

                    const activeClass = workDay.isActive ? "flex-grow-1 text-right green-text" : "flex-grow-1 text-right red-text";

                    workSchedule.push([<strong key={ "date" + i }>{ weekDays[i] }</strong>, <span key={ "isActive" + i }
                                                                                                  className={ activeClass }>{ workDay.isActive ? strings.active : tempTimeSlots.length ? strings.notActive : "" }</span>,
                        <span key={ "time" + i } className="text-right ml-5">{ tempTimeSlots }</span>]);

                }

            } else {

                workSchedule.push([<strong key={ "date" + i }>{ weekDays[i] }</strong>,
                    <span>{ strings.notSet }</span>]);
            }

            tempDayIndex++;

        }

        return workSchedule;
    };

    renderDocuments = () => {

        if (!this.props.currentUser[userTypes.ADVISOR.toLowerCase()]) {
            return;
        }

        const {documents} = this.props.currentUser[userTypes.ADVISOR.toLowerCase()];

        return <Table
            columns={ [strings.name, strings.type, strings.date, strings.action] }
            data={ appUtils.parseDocuments(documents) }
            noItemsMessage={ strings.noDocuments }
        />;
    };

    renderLanguages = () => {

        if (!this.props.currentUser[userTypes.ADVISOR.toLowerCase()]) {
            return;
        }

        const {languages} = this.props.currentUser[userTypes.ADVISOR.toLowerCase()];

        return <Table
            columns={ [strings.language, strings.certificates] }
            data={ appUtils.parseLanguages(languages, this.renderCertificatesTable) }
            noItemsMessage={ strings.noLanguages }
        />;
    };

    renderAdvisorSections = () => {

        if (this.props.currentUser.userType !== userTypes.ADVISOR) {
            return;
        }

        return (
            <Fragment>
                <div className="row p-0">
                    <div className="col-6">
                        <Section title={ strings.documents }/>
                        { this.renderDocuments() }
                    </div>
                    <div className="col-6">
                        <Section title={ strings.languages }/>
                        { this.renderLanguages() }
                    </div>
                </div>
                <Transactions/>
            </Fragment>
        );
    };

    renderCertificatesTable = (certificates) => {

        return (
            <div className="table-dialog">
                <Table
                    columns={ [strings.ID, strings.name, strings.date, strings.action] }
                    data={ appUtils.parseCertificates(certificates) }
                />
            </div>);
    };

    renderRefundsTable = (paidRequests) => {

        const correspondent = this.props.currentUser.userType === userTypes.CLIENT ? strings.advisor : strings.client;
        let amount = 0;

        for (let request of paidRequests) {
            amount += request.durations[0].price;
        }

        amount = strings.CHF + " " + amount;

        return (
            <div key={ "uncompletedRequestTable" } className="table-dialog">
                <Table
                    columns={ [strings.requestId, correspondent, strings.amount, strings.action] }
                    data={ appUtils.parseUncompletedPaidRequests(paidRequests) }
                    amount={ amount }
                    amountColumn={ 2 }
                />
            </div>);
    };

    blockUserButton() {

        const {currentUser} = this.props;

        return {
            title: strings.blockUser, danger: true, invert: true, action: () => {
                this.props.getUserUncompletedPaidRequests(currentUser.id).then((response) => {

                    const content = [];

                    if (response.data.error) {
                        appUtils.errorHandle(response.data);
                    } else if (response.data.data && response.data.data.paidRequests && response.data.data.paidRequests.length > 0) {
                        content.push(<p key={ "message" }
                                        className="dialog-error-message mb-0 text-center">{ strings.blockUserRefundNote }</p>);
                        content.push(<span key={ "title" }
                                           className="dialog-category-title">{ strings.requestsWaitingRefund }</span>);
                        content.push(this.renderRefundsTable(response.data.data.paidRequests));
                    } else {
                        content.push(<p key={ "message" }
                                        className="dialog-error-message mb-0 text-center ">{ strings.blockUserMessage }</p>);
                    }

                    this.props.showDialogLarge(strings.warning, content,
                        {
                            title: strings.block, danger: true, action: () => {
                                this.props.blockUnblockUser(currentUser.id);
                            }
                        },
                        {
                            title: strings.cancel, close: true
                        }
                    );
                });
            }
        };
    }

    accountStatusButton() {

        const {currentUser} = this.props;

        if (currentUser.translator && currentUser.translator.accountStatus.status !== AccountStatuses.PENDING) {

            return {
                title: strings.changeStatus, danger: false, invert: false, action: () => {

                    const content = [];

                    content.push(<p key={ "message" }
                                    className="dialog-error-message mb-0 text-center">{ strings.accountStatusDescription }</p>);
                    content.push(<AccountStatusForm key={ "radioButtons" }/>);
                    content.push(<label key={ "space" } className="input-label"/>);

                    this.props.showDialogLarge(strings.accountStatus, content,
                        {
                            title: strings.apply, action: () => {
                                this.props.submit("accountStatusForm");
                            }
                        },
                        {
                            title: strings.cancel, close: true, danger: true
                        }
                    );
                }
            };

        }
    }

    unblockUserButton() {

        const {currentUser} = this.props;
        const content = [];

        content.push(<p key={ "message" }
                        className="dialog-error-message mb-0 text-center ">{ strings.unblockUserMessage }</p>);

        return {
            title: strings.unblockUser, invert: true, action: () => {
                this.props.showDialogLarge(strings.warning, content,
                    {
                        title: strings.unblock, action: () => {
                            this.props.blockUnblockUser(currentUser.id);
                        }
                    },
                    {
                        title: strings.cancel, danger: true, close: true
                    }
                );
            }
        };
    }

    blockUnblockUserButton() {

        const {currentUser} = this.props;

        if (currentUser.userType !== userTypes.NOT_SELECTED) {

            if (!currentUser.isBlocked) {
                return this.blockUserButton();
            } else {
                return this.unblockUserButton();
            }
        }
    }

    deleteButton() {

        const {currentUser} = this.props;

        return {
            title: strings.deleteUser, danger: true, action: () => {
                this.props.showDialog(strings.warning, strings.deleteUserMessage,
                    {
                        title: strings.delete, danger: true, action: () => {
                            this.props.deleteUser(currentUser.id);
                        }
                    },
                    {
                        title: strings.cancel, close: true
                    }
                );
            }
        };
    }

    renderUserRequests() {

        if (this.props.currentUser.userType === userTypes.NOT_SELECTED) {
            return;
        }

        return <Requests/>;
    }

    render() {

        const {currentUser, expertises} = this.props;

        if (appUtils.isEpmtyObject(currentUser) || !expertises) {
            return <Fragment/>;
        }

        const pageTitle = "User #" + currentUser.id;

        return (<div className="page-container flex-column flex-fill">
            <TopicSection
                title={ pageTitle }
                buttons={ [
                    this.accountStatusButton(),
                    this.blockUnblockUserButton(),
                    this.deleteButton()
                ] }
            />
            <div className="row p-0 m-0">
                { this.renderDetails() }
                { this.renderAdvanceDetails() }
            </div>
            { this.renderAdvisorSections() }
            { this.renderUserRequests() }
        </div>);
    }
}

const mapStateToProps = state => {

    return {
        currentUser: state.users.currentUser,
        expertises: state.resources.expertises,
        expertiseCategories: state.resources.expertiseCategories
    };
};

export default connect(mapStateToProps, {
    getUserDetails,
    showDialog,
    showDialogLarge,
    deleteUser,
    getUserUncompletedPaidRequests,
    changeAccountStatus,
    blockUnblockUser,
    getFile,
    submit
})(UserPreviewPage);
