import React, { Component, Fragment } from "react";

class Details extends Component {

    render() {

        let detailsStyle = this.props.white ? "details-container details-white row" : "details-container row";

        if (this.props.className) {
            detailsStyle += " " + this.props.className
        }

        return (
            <Fragment>
                <div className={ detailsStyle }>
                    { this.props.children }
                </div>
            </Fragment>
        );
    }
}

export default Details;