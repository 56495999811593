import React, { Component } from "react";
import Section from "../../common/Section";
import Table from "../../common/Table";
import { strings } from "../../../resources/strings";
import { Fields } from "../../common/Fields";
import FormButton from "../../common/buttons/FormButton";
import { getFormValues, reduxForm } from "redux-form";
import { formUtils } from "../../../utils/formUtils";
import { connect } from "react-redux";
import { exportData, getUsers, setPagination } from "../../../actions";
import { appUtils } from "../../../utils/appUtils";
import Pagination from "../../common/Pagination";
import TopicSection from "../../common/TopicSection";
import { paginationTypes } from "../../../constants/app";

class UsersPage extends Component {

    onFormSubmit = (formValues) => {
        this.props.getUsers(formValues);
        this.props.setPagination(paginationTypes.USERS, 1);
    };

    componentDidMount() {
        this.props.getUsers(this.props.formValues, this.props.page);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.page !== this.props.page) {
            this.props.getUsers(this.props.formValues, this.props.page);
        }
    }

    setCurrentPage = (page) => {
        this.props.setPagination(paginationTypes.USERS, page);
    };

    renderAccountStatusFormField() {
        const {formValues} = this.props;

        if (!formValues || !formValues.type || formValues.type !== "TRANSLATOR") {
            return;
        }

        return Fields.accountStatus;
    }

    renderForm = () => {

        return (
            <form
                onSubmit={ this.props.handleSubmit(this.onFormSubmit) }
            >
                { Fields.userId }
                { Fields.firstName }
                { Fields.lastName }
                { Fields.emailFilter }
                { Fields.type }
                { this.renderAccountStatusFormField() }
                { Fields.orderBy }
                <FormButton
                    onPress={ this.onFormSubmit }
                    className="margin-bottom-m margin-top-m"
                    title={ strings.filter }
                />
            </form>
        );
    };

    renderPagination = () => {
        const {users} = this.props;
        if (users) {
            return (
                <Pagination
                    currentPage={ this.props.page }
                    total={ users.total }
                    setCurrentPage={ this.setCurrentPage }
                />
            );
        }
    };

    renderUsers = () => {

        const users = appUtils.parseUsers(this.props.users.data);

        return <Table
            columns={ [strings.ID, strings.name, strings.type, strings.email, strings.completedRequests, strings.action] }
            data={ users }
        />;
    };

    render() {

        return (
            <div className="page-container flex-column flex-fill">

                <div className="row p-0">
                    <div className="col-10">
                        <TopicSection
                            title={ strings.users }
                            buttons={ [
                                {
                                    title: strings.exportAllClients,
                                    action: () => {
                                        this.props.exportData("clients");
                                    }
                                },
                                {
                                    title: strings.exportAllAdvisors,
                                    action: () => {
                                        this.props.exportData("advisors");
                                    }
                                }

                            ] }
                        />
                        { this.renderUsers() }
                        { this.renderPagination() }
                    </div>
                    <div className="col-2">
                        <Section title={ strings.filters } first/>
                        { this.renderForm() }
                    </div>
                </div>
            </div>);
    }
}

const usersFilterForm = reduxForm({form: "usersFilterForm", validate: formUtils.validate})(UsersPage);
const mapStateToProps = state => {
    return {
        users: state.users.users,
        formValues: getFormValues("usersFilterForm")(state),
        page: state.pagination[paginationTypes.USERS]
    };
};

export default connect(mapStateToProps, {getUsers, exportData, setPagination})(usersFilterForm);