export const prefix = "";

export const pagesPaths = {
    ADMIN: prefix + "/",
    DASHBOARD: prefix + "/dashboard",
    USERS: prefix + "/users",
    PAGE_NOT_FOUND: "/404",
    REQUESTS: prefix + "/requests",
    PENDING_PAYMENTS: prefix + "/pending-payments",
    CONFLICTS: prefix + "/conflicts",
    SETTINGS: prefix + "/settings",
    CREATE_ADMIN: prefix + "/settings/create",
    BLOCKED_USERS: prefix + "/blocked-users"
};
