import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import DefaultButton from "./buttons/DefaultButton";
import { hideDialogLarge } from "../../actions";

class DialogLarge extends React.Component {

    renderPrimaryButton = () => {

        const {primaryButton} = this.props.dialog;

        if (!primaryButton) {
            return;
        }

        const action = primaryButton.close ? this.props.hideDialogLarge : primaryButton.action;
        const danger = primaryButton.danger;
        const buttonStyle = this.props.dialog.secondaryButton ? "d-flex flex-fill col-6 pl-0" : "d-flex flex-fill col-6";

        if (primaryButton) {
            return (
                <div className={buttonStyle}>
                    <DefaultButton danger={ danger } title={ primaryButton.title } onPress={ action }/>
                </div>
            );
        }
    };

    renderSecondaryButton = () => {

        const {secondaryButton} = this.props.dialog;

        if (!secondaryButton) {
            return;
        }

        const action = secondaryButton.close ? this.props.hideDialogLarge : secondaryButton.action;
        const danger = secondaryButton.danger;

        if (secondaryButton) {
            return (
                <div className="d-flex flex-fill col-6 pr-0">
                    <DefaultButton danger={ danger } title={ secondaryButton.title } onPress={ action }/>
                </div>);
        }
    };

    render() {

        if (this.props.dialog && this.props.dialog.showDialog) {
            return ReactDOM.createPortal(
                <Fragment>
                    <div className="eclipse flex-fill">
                        <div className="dialog-large-container ">
                            <h1 className="text-center dialog-title-large">{ this.props.dialog.title }</h1>
                            { this.props.dialog.view }
                            <div className="dialog-button-container row justify-content-center">
                                { this.renderPrimaryButton() }
                                { this.renderSecondaryButton() }
                            </div>
                        </div>
                    </div>
                </Fragment>
                ,
                document.querySelector("#dialog-large")
            );
        }
        return <Fragment/>;
    }
}

const mapStateToProps = state => {
    return {dialog: state.dialog.dialogLarge};
};

export default connect(mapStateToProps, {hideDialogLarge})(DialogLarge);