import React, { Fragment } from "react";
import ReactPagination from "react-js-pagination";
import { ITEMS_PER_PAGE } from "../../constants/app";
import { strings } from "../../resources/strings";

class Pagination extends React.Component {

    handlePageChange = (pageNumber) => {
        this.props.setCurrentPage(pageNumber);
    };

    renderItemCounter = (totalElements, currentPage, ITEMS_PER_PAGE) => {

        const lastItem = (currentPage * ITEMS_PER_PAGE) > totalElements ? totalElements : (currentPage * ITEMS_PER_PAGE);
        const firstItem = (currentPage * ITEMS_PER_PAGE) - ITEMS_PER_PAGE + 1;
        const data = firstItem + " - " + lastItem + " " + strings.of.toLowerCase() + " " + totalElements;

        return (
            <p className="items-count col-3 pl-0 m-0">{ data }</p>
        );
    };

    render() {

        const currentPage = this.props.currentPage ? this.props.currentPage : 1;
        const totalElements = this.props.total;
        const size = ITEMS_PER_PAGE;

        // eslint-disable-next-line eqeqeq
        if (!totalElements || totalElements == 0) {
            return <Fragment/>;
        }

        return (
            <div className="text-center justify-content-center d-flex pagination-container row ">
                <ReactPagination
                    activePage={ currentPage }
                    itemsCountPerPage={ size }
                    totalItemsCount={ totalElements }
                    pageRangeDisplayed={ 5 }
                    onChange={ this.handlePageChange }
                    activeClass={ "page-item active" }
                    activeLinkClass={ "page-link" }
                    itemClass={ "page-item" }
                    linkClass={ "page-link" }
                    disabledClass={ "page-item disabled" }
                    innerClass={ "pagination col-6 offset-3 pr-0 justify-content-center" }
                    prevPageText={ "<" }
                    nextPageText={ ">" }
                    firstPageText={ "<<" }
                    lastPageText={ ">>" }
                />
                { this.renderItemCounter(totalElements, currentPage, ITEMS_PER_PAGE) }
            </div>
        );
    };
}

export default Pagination;