import React from "react";
import { Field } from "redux-form";
import { formUtils } from "../../utils/formUtils";
import { strings } from "../../resources/strings";
import {
    ACCOUNT_STATUS_OPTIONS, AccountStatuses,
    ORDER_REQUESTS_OPTIONS,
    ORDER_USERS_OPTIONS,
    REQUEST_STATUSES,
    USER_TYPE_OPTIONS,
    ALL_ACCOUNT_STATUS_OPTIONS
} from "../../constants/app";
import store from "../../store";

const textInput = ({input, label, meta, type, disabled = false}) => {

    return (
        <div className="text-left mt-3">
            <label className="input-label">{ label }</label>
            <input autoComplete="none" className={ formUtils.getFieldStyle(meta) } { ...input } type={ type }
                   disabled={ disabled }/>
            { formUtils.renderErrorMessage(meta) }
        </div>
    );
};

const selectInput = ({input, label, meta, options}) => {

    options = options.map((option, index) => {
        return <option key={ option.value + index } value={ option.value }>{ option.name }</option>;
    });

    return (
        <div className="text-left mt-3">
            <label className="input-label">{ label }</label>
            <select className="form-control form-field white-input-field" { ...input }>
                { options }
            </select>
            { formUtils.renderErrorMessage(meta) }
        </div>
    );
};

const radioInput = ({input, label, meta, options}) => {

    const labelClass = "input-label";

    const radioButtons = <div>{ options.map((button, index) => {
        const radioId = index + "radioButton";
        const labelClass = button.value === AccountStatuses.REJECTED ? "input-label red-text" : button.value === AccountStatuses.PUBLISHED ? "input-label green-text" : "input-label";
        return (
            <div key={ radioId } className='radio'>
                <input id={ radioId }
                       autoComplete="none"
                       className={ formUtils.getFieldStyle(meta) } { ...input }
                       type='radio'
                       value={ button.value }
                       checked={ input.value === button.value }
                />
                <label htmlFor={ radioId } className={ labelClass }>{ button.name }</label>
            </div>
        );
    }) }</div>;

    return (
        <div className="d-flex align-items-start flex-column">
            <label className={ labelClass }>{ label }</label>
            { radioButtons }
        </div>
    );
};

const textArea = ({input, label, meta}) => {
    return (
        <div>
            <label className="input-label">{ label }</label>
            <textarea className="form-control white-input-field" type="text" { ...input }/>
            { formUtils.renderErrorMessage(meta) }
        </div>
    );
};

export const Fields = {
    email: <Field name="email" label={ strings.email } type="text" component={ textInput }/>,
    emailFilter: <Field name="emailFilter" label={ strings.email } type="text" component={ textInput }/>,
    emailDisabled: <Field name="emailDisabled" label={ strings.email } disabled={ true } type="text"
                          component={ textInput }/>,
    password: <Field name="password" label={ strings.password } type="password" component={ textInput }/>,
    oldPassword: <Field name="oldPassword" label={ strings.oldPassword } type="password" component={ textInput }/>,
    repeatPassword: <Field name="repeatPassword" label={ strings.repeatPassword } type="password"
                           component={ textInput }/>,
    firstName: <Field name="firstName" label={ strings.firstName } type="text" component={ textInput }/>,
    lastName: <Field name="lastName" label={ strings.lastName } type="text" component={ textInput }/>,
    type: <Field name="type" label={ strings.type } options={ USER_TYPE_OPTIONS } component={ selectInput }/>,
    accountStatus: <Field name="accountStatus" label={ strings.accountStatus } options={ ALL_ACCOUNT_STATUS_OPTIONS } component={ selectInput }/>,
    orderBy: <Field name="orderBy" label={ strings.orderBy } options={ ORDER_USERS_OPTIONS }
                    component={ selectInput }/>,
    userId: <Field name="userId" label={ strings.userId } type="number" component={ textInput }/>,
    requestId: <Field name="id" label={ strings.requestId } type="number" component={ textInput }/>,
    requestsOrderBy: <Field name="orderBy" label={ strings.orderBy } options={ ORDER_REQUESTS_OPTIONS }
                            component={ selectInput }/>,
    requestStatus: <Field name="status" label={ strings.status } options={ REQUEST_STATUSES }
                          component={ selectInput }/>,
    newStatus: <Field name="newStatus" label={ strings.status } options={ ACCOUNT_STATUS_OPTIONS }
                      component={ radioInput }/>,
    languageFrom: <Field name="languageFrom" label={ strings.languageFrom } options={ store.getState().languages }
                         component={ selectInput }/>,
    languageTo: <Field name="languageTo" label={ strings.languageTo } options={ store.getState().languages }
                       component={ selectInput }/>,
    rejectReason: <Field name="rejectReason" label={ strings.rejectReason } type="text" component={ textArea }/>

};
