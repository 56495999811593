import React, { Component } from "react";
import Section from "../../common/Section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { appUtils } from "../../../utils/appUtils";
import Table from "../../common/Table";
import { strings } from "../../../resources/strings";
import { getStatistics, getConflicts } from "../../../actions";
import { connect } from "react-redux";
import TopicSection from "../../common/TopicSection";
import navigationUtils from "../../../utils/navigationUtils";
import { pagesPaths } from "../../../constants/pagesPaths";

class DashboardPage extends Component {

    componentDidMount() {
        this.props.getStatistics();
        this.props.getConflicts();
    }

    renderStatisticItem = (item) => {

        const statisticStyleDetails = appUtils.getStatisticStyleDetails(item);
        const iconClass = "statistic-icon-container " + statisticStyleDetails.color;
        const count = item.count ? item.count : 0;
        const refinedCount = statisticStyleDetails.currency ? statisticStyleDetails.currency + " " + count.toFixed(2) : count;

        return (
            <div key={ item.description } className="col-md-6 col-lg-6 col-xl-4">
                <div className="statistic-container">
                    <div className={ iconClass }>
                        <FontAwesomeIcon icon={ statisticStyleDetails.icon } className="statistic-icon"/>
                    </div>
                    <div className="statistic-text-container text-right">
                        <p className="m-0 statistic-title">{ refinedCount }</p>
                        <p className="m-0 statistic-description">{ item.description }</p>
                    </div>
                </div>
            </div>
        );
    };

    renderStatistic = () => {

        const statistics = appUtils.parseStatistics(this.props.statistics);

        return statistics.map((statistic, index) => {
            return this.renderStatisticItem(statistic);
        });
    };

    renderConflicts = () => {

        const conflicts = appUtils.parseConflicts(this.props.conflicts.data);

        return <Table
            columns={ [strings.ID, strings.requestId, strings.initiator, strings.initiatorType, strings.status, strings.date, strings.action] }
            data={ conflicts }
        />;
    };

    render() {

        return (
            <div className="page-container flex-column flex-fill">
                <div className="flex-column">
                    <TopicSection title={strings.dashboard}/>
                    <Section title={ strings.statistics } first/>
                    <div className="row">
                        { this.renderStatistic() }
                    </div>
                    <Section title={ strings.latestConflicts } buttonTitle={ strings.viewAllConflicts } onSectionButtonPress={
                        ()=>{navigationUtils.navigate(pagesPaths.CONFLICTS)}
                    }/>
                    { this.renderConflicts() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        conflicts: state.conflicts.conflicts,
        statistics: state.statistics
    };
};

export default connect(mapStateToProps, {getConflicts, getStatistics})(DashboardPage);