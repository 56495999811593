import { hideGlobalLoader, showGlobalLoader } from "./loaderActions";
import { hideDialogLarge } from "./dialogActions";
import { createAxios as axios } from "../api/server";
import { actions } from "../constants/actions";
import { appUtils } from "../utils/appUtils";

export const getConflicts = (page, perPage) => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/conflicts", {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        },
        params: {
            page,
            perPage
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.GET_CONFLICTS, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());

};

export const getConflict = id => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/conflicts/" + id, {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data, true);
    } else {
        dispatch({type: actions.GET_CONFLICT, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());

};

export const typeComment = comment => {
    return {type: actions.TYPE_COMMENT, payload: comment};
};

export const updateConflict = (id, status, comment) => async (dispatch, getState) => {
    const {myAccount} = getState().admin;
    const body = {
        status,
        comment
    };

    dispatch(showGlobalLoader());

    const response = await axios.post("admin/conflicts/" + id, body, {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch(hideDialogLarge());
        dispatch({type: actions.UPDATE_CONFLICT, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());

};