import { strings } from "../resources/strings";

export const expertiseUtils = {

    getExpertiseNameById(expertiseId, expertises) {

        if (expertiseId && expertises && expertises.length > 0) {

            for (let i = 0; i < expertises.length; i++) {
                if (expertises[i].id === expertiseId) {
                    return strings[expertises[i].key];
                }
            }
        }

        return "Not Found"
    },

    getExpertiseCategoryNameById(expertiseCategoryId, expertiseCategories) {

        if (expertiseCategoryId && expertiseCategories && expertiseCategories.length > 0) {

            for (let i = 0; i < expertiseCategories.length; i++) {
                if (expertiseCategories[i].id === expertiseCategoryId) {
                    return strings[expertiseCategories[i].key];
                }
            }
        }

        return "Not Found"
    },

};