import { actions } from "../constants/actions";

const initState = {};

const statisticReducer = (state = {...initState}, action) => {

    if (action.type === actions.GET_STATISTICS) {
        state = {...action.payload};
        return {...state};
    }

    return state;
};

export default statisticReducer;