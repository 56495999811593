import Axios from "axios";
import { BASE_API_URL } from "../constants/paths";
import store from "../store";
import { hideGlobalLoader, hideDialog, showDialog } from "../actions";
import { strings } from "../resources/strings";

export const createAxios = (() => {

    const axios = Axios.create({
        baseURL: BASE_API_URL
    });

    axios.interceptors.request.use(
        conf => {
            return conf;
        },
        error => {
            store.dispatch(hideGlobalLoader());
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            store.dispatch(showDialog(strings.error, strings.networkErrorMessage, {
                title: strings.close,
                action: () => {
                    store.dispatch(hideDialog());
                }
            }));
            store.dispatch(hideGlobalLoader());
            return Promise.reject(error);
        }
    );

    return axios;

})();