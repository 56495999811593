import { hideGlobalLoader, showGlobalLoader } from "./loaderActions";
import { hideDialogLarge, showDialog } from "./dialogActions";
import { createAxios as axios } from "../api/server";
import { actions } from "../constants/actions";
import { strings } from "../resources/strings";
import { appUtils } from "../utils/appUtils";

export const getAdvisorsPendingPayments = page => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/pending-payments", {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        },
        params: {
            page,
            perPage: 10
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.GET_ADVISORS_PENDING_PAYMENTS, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());

};

export const selectPendingPaymentRequest = id => {
    return {type: actions.SELECT_PENDING_PAYMENT_REQUEST, payload: id};
};

export const updatePendingPaymentRequests = requests => {
    return {type: actions.UPDATE_PENDING_PAYMENT_REQUESTS, payload: requests};
};

export const markRequestsAsPaid = (requests, advisorId, userId) => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    const requestBody = {payRequests: requests};

    dispatch(showGlobalLoader());

    const response = await axios.post("admin/pending-payments/" + advisorId, requestBody, {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch(hideDialogLarge());
        dispatch(showDialog(strings.success, strings.successfulMarkedRequestsMessage, {
            title: strings.ok,
            close: true
        }));
        dispatch({type: actions.MARK_REQUESTS_AS_PAID, payload: {requests, userId}});
    }

    dispatch(hideGlobalLoader());

};
