import React, { Component, Fragment } from "react";
import TopicSection from "../../common/TopicSection";
import { strings } from "../../../resources/strings";
import Section from "../../common/Section";
import CreateAdmin from "./CreateAdmin";
import PersonalDetails from "./PersonalDetails";
import DefaultButton from "../../common/buttons/DefaultButton";
import { showDialog, getCurrentAdmin, deleteAdmin, hideDialog } from "../../../actions";
import { connect } from "react-redux";

class AddEditAdminPage extends Component {

    componentDidMount() {
        if (!isNaN(this.props.match.params.id)) {
            this.props.getCurrentAdmin(this.props.match.params.id);
        }
    }

    renderCreateAdmin = () => {

        if (isNaN(this.props.match.params.id)) {
            return (
                <div className="row align-items-center justify-content-center">
                    <div className="col-6">
                        <CreateAdmin/>
                    </div>
                </div>
            );
        }
    };

    renderDeleteAdmin = () => {
        return (
            <Fragment>
                <Section title={ strings.deleteAdmin } first/>
                <div className="col-4 p-0 delete-admin-button">
                    <DefaultButton title={ strings.delete } danger={ true } onPress={
                        () => {
                            this.props.showDialog(strings.warning, strings.deleteAdminMessage,
                                {
                                    title: strings.yes, danger: true, action: () => {
                                        this.props.deleteAdmin(this.props.currentAdmin.id);
                                        this.props.hideDialog();
                                    }
                                },
                                {
                                    title: strings.no, close: true
                                }
                            );
                        }
                    }/>
                </div>
            </Fragment>
        );
    };

    renderEditAdmin = () => {

        if (!isNaN(this.props.match.params.id)) {
            return (
                <div className="row">
                    <div className="col-6">
                        <PersonalDetails/>
                    </div>
                    <div className="col-6">
                        { this.renderDeleteAdmin() }
                    </div>
                </div>
            );
        }
    };

    render() {
        return (
            <div className="page-container flex-column flex-fill">
                <TopicSection title={ strings.settings }/>
                { this.renderCreateAdmin() }
                { this.renderEditAdmin() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {currentAdmin: state.admin.currentAdmin};
};

export default connect(mapStateToProps, {showDialog, getCurrentAdmin, deleteAdmin, hideDialog})(AddEditAdminPage);