import React, { Component, Fragment } from "react";
import TopicSection from "../../common/TopicSection";
import { strings } from "../../../resources/strings";
import { connect } from "react-redux";
import { getFile, getRequest } from "../../../actions";
import Details from "../../common/Details";
import { appUtils } from "../../../utils/appUtils";
import Detail from "../../common/Detail";
import { defaultAvatarUrl, durationStatuses, userTypes } from "../../../constants/app";
import { expertiseUtils } from "../../../utils/expertiseUtils";
import Section from "../../common/Section";
import { STRIPE_PAYMENTS_PATH } from "../../../constants/paths";
import Table from "../../common/Table";
import navigationUtils from "../../../utils/navigationUtils";
import { pagesPaths } from "../../../constants/pagesPaths";

class RequestPreviewPage extends Component {

    componentDidMount() {
        this.props.getRequest(this.props.match.params.id);
    }

    renderExtends = () => {
        let {durations} = this.props.currentRequest;
        durations = [...durations];
        durations.shift();
        durations = appUtils.parseDurations(durations);

        return <Table
            columns={ ["", strings.status, strings.startTime, strings.duration] }
            data={ durations }
            noItemsMessage={ strings.noExtends }
        />;
    };

    renderTotal = () => {

        function isPaid(status) {
            if (status === durationStatuses.COMPLETED || status === durationStatuses.IN_PROGRESS || status === durationStatuses.PAID) {
                return true;
            }
            return false;
        }

        let {durations} = this.props.currentRequest;
        durations = [...durations];
        const firstDuration = durations.shift();
        let requestPrice = firstDuration.price;
        let requestStripePaymentId = <a target="_blank" rel="noopener noreferrer"
                                        href={ STRIPE_PAYMENTS_PATH + firstDuration.paymentId }>{ firstDuration.paymentId }</a>;
        requestPrice = strings.CHF + " " + requestPrice;
        let requestData = [strings.request, requestStripePaymentId, requestPrice];
        let totalData = [];
        let amount = 0;

        if (isPaid(firstDuration.status)) {
            totalData.push(requestData);
            amount += firstDuration.price;
        }

        for (let index in durations) {
            const duration = durations[index];
            if (isPaid(duration.status)) {
                let extendName = strings.extend + " #" + (+index + 1);
                let extendStripePaymentId = <a target="_blank" rel="noopener noreferrer"
                                               href={ STRIPE_PAYMENTS_PATH + duration.paymentId }>{ duration.paymentId }</a>;
                let extendPrice = strings.CHF + " " + duration.price;
                amount += duration.price;
                totalData.push([extendName, extendStripePaymentId, extendPrice]);
            }
        }

        amount = amount !== 0 ? strings.CHF + " " + amount : null;

        return <Table
            amount={ amount }
            amountColumn={ 2 }
            columns={ [strings.type, strings.stripePaymentId, strings.price] }
            data={ totalData }
            noItemsMessage={ strings.noTotal }
        />;
    };

    renderDetailColumnItems = details => {
        return details.map((detail) => {
            if (!detail.value) {
                return null;
            }
            return <Detail key={ detail.title } title={ detail.title } value={ detail.value }/>;
        });
    };

    renderFirstDetailColumn = () => {
        const {currentRequest} = this.props;
        const createdOn = appUtils.parseDateTime(currentRequest.createdAt);
        const status = currentRequest.clientImportance ? appUtils.parseStatusFromNumber(currentRequest.clientImportance) : strings.notSet;

        const details = [
            {title: strings.requestId, value: currentRequest.id},
            {title: strings.status, value: status},
            {title: strings.createdOn, value: createdOn}
        ];

        return this.renderDetailColumnItems(details);
    };

    renderSecondDetailColumn = () => {
        const {currentRequest} = this.props;

        const startTime = appUtils.parseDateTime(currentRequest.startTime);
        const advisor = currentRequest[userTypes.ADVISOR.toLowerCase()] ? currentRequest[userTypes.ADVISOR.toLowerCase()].user.firstName + " " + currentRequest[userTypes.ADVISOR.toLowerCase()].user.lastName : strings.deleted;
        const client = currentRequest[userTypes.CLIENT.toLowerCase()] ? currentRequest[userTypes.CLIENT.toLowerCase()].user.firstName + " " + currentRequest[userTypes.CLIENT.toLowerCase()].user.lastName : strings.deleted;

        const details = [
            {title: strings.startDate, value: startTime},
            {title: strings.advisor, value: advisor},
            {title: strings.client, value: client}
        ];

        return this.renderDetailColumnItems(details);
    };

    renderThirdDetailColumn = () => {
        const {currentRequest} = this.props;

        const duration = currentRequest.durations[0].duration + " " + strings.min;
        const languages = appUtils.parseLanguagesFromTo(currentRequest.languageFrom, currentRequest.languageTo);
        const expertise = expertiseUtils.getExpertiseNameById(currentRequest.expertiseId, this.props.expertises);

        const details = [
            {title: strings.duration, value: duration},
            {title: strings.languages, value: languages},
            {title: strings.expertise, value: expertise}
        ];

        return this.renderDetailColumnItems(details);
    };

    fourthDetailColumn = () => {
        const {currentRequest} = this.props;

        const expertiseCategory = expertiseUtils.getExpertiseCategoryNameById(currentRequest.expertiseCategoryId, this.props.expertiseCategories);
        const description = currentRequest.description;

        const details = [
            {title: strings.expertiseCategory, value: expertiseCategory},
            {title: strings.description, value: description}
        ];

        return this.renderDetailColumnItems(details);
    };

    renderDetails = () => {

        return (
            <Details>
                <div className="col-3">
                    { this.renderFirstDetailColumn() }
                </div>
                <div className="col-3">
                    { this.renderSecondDetailColumn() }
                </div>
                <div className="col-3">
                    { this.renderThirdDetailColumn() }
                </div>
                <div className="col-3 align-self-start">
                    { this.fourthDetailColumn() }
                </div>
            </Details>
        );
    };

    renderMessages = () => {

        const {messages} = this.props.currentRequest;
        const messagesItems = messages.map((message, index) => {

            const messageItem = message.userType === userTypes.ADVISOR ? this.renderRightMessageItem(message) : this.renderLeftMessageItem(message);

            return <Fragment key={ "m" + index }>{ messageItem }</Fragment>;
        });

        const noMessages = messages.length === 0 ?
            <span className="m-auto">{ strings.noMessages }</span> :
            <Fragment/>;

        return (
            <div className="messages-container">
                { messagesItems }
                { noMessages }
            </div>
        );
    };

    renderFeedback = () => {

        const {feedbacks} = this.props.currentRequest;

        if (!feedbacks || feedbacks.length === 0) {
            return null;
        }

        const refinedFeedbacks = appUtils.parseFeedbacks(feedbacks, this.props.currentRequest);
        return (
            <div className="row p-0 m-0">
                <div className="col-12 pr-0 pl-0">
                    <Section title={ strings.feedback }/>
                    <Table
                        columns={ ["User Type", "User Name", "Feedback Type", "Rating", "Comment"] }
                        data={ refinedFeedbacks }
                        noItemsMessage={ strings.noFeedback }
                    />
                </div>
            </div>
        );

    };

    renderFiles = files => {
        return files.map((file, index) => {
            return (
                <a className="message-file" key={ "f" + index } rel="noopener noreferrer" onClick={ () => {
                    this.props.getFile(file.path);
                } }>{ file.name }</a>);
        });
    };

    renderLeftMessageItem = message => {

        const {currentRequest} = this.props;
        const client = currentRequest.client;
        const name = client ? client.user.firstName + " " + client.user.lastName : strings.deleted;
        const imageUrl = client && client.user.image ? appUtils.getFilePath(client.user.image.url) : defaultAvatarUrl;
        const createdAt = appUtils.parseDateTime(currentRequest.createdAt);
        const files = message.documents;

        let userNameSpan;

        if (client) {
            userNameSpan = (
                <span className="messages-user-name-left mr-auto url"
                      onClick={ () => this.onClickUserProfile(client.user.id) }>
                        { name }
                </span>
            );
        } else {
            userNameSpan = (
                <span className="messages-user-name-left mr-auto">{ name }</span>
            );
        }

        return (
            <div className="w-75">
                <div className="row m-0 align-items-center">
                    <img className="avatar-message-preview" src={ imageUrl } alt={ name }/>
                    { userNameSpan }
                    <span className="message-date">{ createdAt }</span>
                </div>
                <div className="row m-0 message-text">
                    <span>{ message.text }</span>
                </div>
                <div className="row m-0 message-file-container">
                    { this.renderFiles(files) }
                </div>
            </div>
        );
    };

    onClickUserProfile(userId) {
        if (userId) {
            navigationUtils.navigate(pagesPaths.USERS + "/" + userId);
        }
    };

    renderRightMessageItem = message => {

        const {currentRequest} = this.props;
        const advisor = currentRequest[userTypes.ADVISOR.toLowerCase()];
        const name = advisor ? advisor.user.firstName + " " + advisor.user.lastName : strings.deleted;
        const imageUrl = advisor && advisor.user.image ? appUtils.getFilePath(advisor.user.image.url) : defaultAvatarUrl;
        const createdAt = appUtils.parseDateTime(currentRequest.createdAt);

        let userNameSpan;

        if (advisor) {
            userNameSpan = (
                <span className="messages-user-name-right ml-auto url"
                      onClick={ () => this.onClickUserProfile(advisor.user.id) }>
                        { name }
                </span>
            );
        } else {
            userNameSpan = (
                <span className="messages-user-name-right ml-auto">{ name }</span>
            );
        }

        return (
            <div className="w-75 ml-auto">
                <div className="row m-0 align-items-center">
                    <span className="message-date">{ createdAt }</span>
                    { userNameSpan }
                    <img className="avatar-message-preview" src={ imageUrl } alt={ name }/>
                </div>
                <div className="row m-0 d-flex message-text">
                    <span className="ml-auto">{ message.text }</span>
                </div>
            </div>
        );
    };

    render() {

        const {currentRequest, expertises, expertiseCategories} = this.props;

        if (appUtils.isEpmtyObject(currentRequest) || !expertises || !expertiseCategories) {
            return <Fragment/>;
        }

        const pageTitle = "Request #" + currentRequest.id;

        return (
            <div className="page-container flex-column flex-fill">
                <TopicSection
                    title={ pageTitle }
                />
                <div className="row p-0 m-0">
                    { this.renderDetails() }
                </div>
                { this.renderFeedback() }
                <div className="row p-0 m-0">
                    <div className="col-6 pl-0">
                        <Section title={ strings.messages }/>
                        { this.renderMessages() }
                    </div>
                    <div className="col-6 pr-0">
                        <Section title={ strings.extends }/>
                        { this.renderExtends() }
                        <Section title={ strings.total }/>
                        { this.renderTotal() }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentRequest: state.requests.currentRequest,
        expertises: state.resources.expertises,
        expertiseCategories: state.resources.expertiseCategories
    };
};

export default connect(mapStateToProps, {getRequest, getFile})(RequestPreviewPage);
