import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { formUtils } from "../../../utils/formUtils";
import { Fields } from "../../common/Fields";
import FormButton from "../../common/buttons/FormButton";
import { signIn } from "../../../actions";
import { appUtils } from "../../../utils/appUtils";
import { strings } from "../../../resources/strings";
import navigationUtils from "../../../utils/navigationUtils";
import { pagesPaths } from "../../../constants/pagesPaths";

class SignInPage extends Component {

    onFormSubmit = (formValues) => {
        this.props.signIn(formValues);
    };

    componentDidMount() {
        if (!appUtils.isEpmtyObject(this.props.admin)) {
            navigationUtils.navigate(pagesPaths.DASHBOARD)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!appUtils.isEpmtyObject(this.props.admin)) {
            navigationUtils.navigate(pagesPaths.DASHBOARD)
        }
    }

    renderForm = () => {

        return (
            <div className="login-form-container">
                <div className="login-form">
                    <h1 className="text-center dialog-title">{strings.signIn}</h1>
                    <div className="line"/>
                    <form
                        onSubmit={ this.props.handleSubmit(this.onFormSubmit) }
                    >
                        { Fields.email }
                        { Fields.password }
                        <FormButton
                            onPress={ this.onFormSubmit }
                            className="margin-bottom-m margin-top-m"
                            title={strings.signIn}
                        />
                    </form>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="m-auto flex-fill full-height d-flex align-items-center justify-content-center">
                { this.renderForm() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {admin: state.admin.myAccount};
};

const registerForm = reduxForm({form: "signInForm", validate:formUtils.validate})(SignInPage);

export default connect(mapStateToProps, {signIn})(registerForm);