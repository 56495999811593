import React, { Component } from "react";
import { connect } from "react-redux";
import { appUtils } from "../../../utils/appUtils";
import Table from "../../common/Table";
import { strings } from "../../../resources/strings";
import Section from "../../common/Section";
import Pagination from "../../common/Pagination";
import { getUserRequests, setPagination } from "../../../actions";
import { paginationTypes, userTypes } from "../../../constants/app";

class Requests extends Component {

    setCurrentPage = (page) => {
        this.props.setPagination(paginationTypes.USER_REQUESTS, page);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {currentUser} = this.props;
        let userType = currentUser.userType;
        if (prevProps.page !== this.props.page) {
            this.props.getUserRequests(currentUser.id, currentUser[userType.toLowerCase()].id, currentUser.userType, this.props.page);
        }
    }

    renderTransactions = () => {

        const {currentUser} = this.props;
        let userType = currentUser.userType;
        let requests = currentUser.requests;
        const correspondentType = userType === userTypes.ADVISOR ? appUtils.parseUpperFirstLetter(userTypes.CLIENT) : appUtils.parseUpperFirstLetter(userTypes.ADVISOR);
        const parsedCorrespondentType = appUtils.parseUserType(correspondentType.toUpperCase());

        if (requests) {
            requests = appUtils.parseUserRequests(requests.data, correspondentType);
        } else {
            requests = [];
        }

        return <Table
            columns={ [strings.ID, strings.languages, parsedCorrespondentType, strings.status, strings.date, strings.expertise, strings.expertiseCategory, strings.action] }
            data={ requests }
            noItemsMessage={ strings.noRequests }
        />;
    };

    renderPagination = () => {
        const {requests} = this.props.currentUser;
        if (requests) {
            return (
                <Pagination
                    currentPage={ this.props.page }
                    total={ requests.total }
                    setCurrentPage={ this.setCurrentPage }
                />
            );
        }
    };

    render() {
        return (
            <div className="row p-0">
                <div className="col-12">
                    <Section title={ strings.requests }/>
                    { this.renderTransactions() }
                    { this.renderPagination() }
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {currentUser: state.users.currentUser, page: state.pagination[paginationTypes.USER_REQUESTS]};
};

export default connect(mapStateToProps, {getUserRequests, setPagination})(Requests);