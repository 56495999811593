import { createAxios as axios } from "../api/server";
import { showGlobalLoader, hideGlobalLoader } from "./loaderActions";
import { actions } from "../constants/actions";
import { showDialog, hideDialog } from "./dialogActions";
import navigationUtils from "../utils/navigationUtils";
import { pagesPaths } from "../constants/pagesPaths";
import { strings } from "../resources/strings";
import { getExpertises, getExpertiseCategories } from "./resourceActions";
import { appUtils } from "../utils/appUtils";
import { reset } from "redux-form";
import { adminRights } from "../constants/app";

export const loadAdmin = () => async dispatch => {
    let user = {};
    try {
        const obj = await localStorage.getItem("user");
        if (obj !== null) {
            user = JSON.parse(obj);
        }
    }
    catch (e) {
        user = {};
    }

    if (!appUtils.isEpmtyObject(user)) {
        dispatch(getExpertises(user.accessToken));
        dispatch(getExpertiseCategories(user.accessToken));
    }

    dispatch({type: actions.LOAD_ADMIN, payload: user});
    return user;
};

export const saveAdmin = (state) => async dispatch => {
    try {
        const obj = JSON.stringify(state);
        await localStorage.setItem("user", obj);
    }
    catch (e) {
    }
};

export const signIn = formValues => async (dispatch) => {

    dispatch(showGlobalLoader());

    const response = await axios.post("admin/login", formValues, {});

    if (response.data.error) {
        dispatch(showDialog(strings.error, response.data.userMessage, {
            title: strings.close, action: () => {
                dispatch(hideDialog());
            }
        }));

    } else {
        dispatch(getExpertises(response.data.data.accessToken));
        dispatch(getExpertiseCategories(response.data.data.accessToken));
        dispatch({type: actions.SIGN_IN, payload: response.data.data});
        dispatch(saveAdmin(response.data.data));
    }

    dispatch(hideGlobalLoader());

};

export const signOut = () => async (dispatch) => {

    dispatch(showGlobalLoader());
    dispatch({type: actions.SIGN_OUT});
    dispatch(saveAdmin(null));
    dispatch(hideGlobalLoader());
    navigationUtils.navigate(pagesPaths.ADMIN);

};

const editAdmin = async (formValues, id, accessToken) => {

    const body = {
        ...formValues,
        password: formValues.oldPassword ? formValues.oldPassword : formValues.password,
        newPassword: formValues.password
    };

    const response = await axios.post("admins/" + id, body, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    });

    return response;

};

export const changePassword = (formValues, id) => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    editAdmin(formValues, id, myAccount.accessToken).then(response => {
        if (response.data.error) {
            dispatch(showDialog(strings.error, response.data.userMessage, {
                title: strings.close, action: () => {
                    dispatch(hideDialog());
                }
            }));
        } else {
            dispatch(reset("passwordForm"));
            dispatch(showDialog(strings.success, strings.successfulChangedPasswordMessage, {
                title: strings.close, action: () => {
                    dispatch(hideDialog());
                }
            }));
            dispatch({type: actions.UPDATE_PASSWORD, payload: response.data.data});
        }
    });

    dispatch(hideGlobalLoader());
};

export const editPersonalDetails = (formValues, id) => async (dispatch, getState) => {
    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    editAdmin(formValues, id, myAccount.accessToken).then(response => {
        if (response.data.error) {
            dispatch(showDialog(strings.error, response.data.userMessage, {
                title: strings.close, action: () => {
                    dispatch(hideDialog());
                }
            }));
        } else {
            dispatch(showDialog(strings.success, strings.successfulEditedDetailsMessage, {
                title: strings.close, action: () => {
                    dispatch(hideDialog());
                }
            }));
            dispatch({type: actions.UPDATE_CURRENT_ADMIN, payload: response.data.data});
            dispatch(saveAdmin(response.data.data));
        }
    });
    dispatch(hideGlobalLoader());
};

export const getAdmins = () => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admins/", {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.GET_ADMINS, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());

};

export const createAdmin = formValues => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    editAdmin(formValues, "", myAccount.accessToken).then(response => {
        if (response.data.error) {
            dispatch(showDialog(strings.error, response.data.userMessage, {
                title: strings.close, action: () => {
                    dispatch(hideDialog());
                }
            }));
        } else {
            dispatch(showDialog(strings.success, strings.successfulCreatedAdminMessage, {
                title: strings.close,
                action: () => {
                    dispatch(hideDialog());
                }
            }));
            navigationUtils.goBack();
        }
    });
    dispatch(hideGlobalLoader());

};

export const getCurrentAdmin = id => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admins/" + id, {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);

    } else {
        dispatch({type: actions.GET_CURRENT_ADMIN, payload: response.data.data});
        if (response.data.data.rights === adminRights.MASTER) {
            dispatch(showDialog(strings.error, strings.updateAdminErrorMessage, {
                title: strings.back,
                action: () => {
                    navigationUtils.goBack();
                    dispatch(hideDialog());
                }
            }));
        }
    }

    dispatch(hideGlobalLoader());

};

export const deleteAdmin = id => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.delete("admins/" + id, {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);

    } else {
        dispatch(showDialog(strings.success, strings.successfulDeletedAdminMessage, {
            title: strings.close,
            action: () => {
                dispatch(hideDialog());
            }
        }));
        navigationUtils.goBack();
    }

    dispatch(hideGlobalLoader());

};
