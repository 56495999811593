import { actions } from "../constants/actions";
import { appUtils } from "../utils/appUtils";
import { strings } from "../resources/strings";

const initState =  [{value: "", name: strings.all}];

const languagesReducer = (state = [...initState], action) => {

    if (action.type === actions.GET_LANGUAGES) {
        for (let language of action.payload) {
            state.push({value: language, name: appUtils.getLanguageFromCode(language) })
        }
        return [...state];
    }

    return state;
};

export default languagesReducer;