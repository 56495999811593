import { actions } from "../constants/actions";

const initState = {globalLoader: false};

const loaderReducer = (state = {...initState}, action) => {
    if (action.type === actions.HIDE_GLOBAL_LOADER) {

        state.globalLoader = false;
        return {...state};

    }

    if (action.type === actions.SHOW_GLOBAL_LOADER) {

        state.globalLoader = true;
        return {...state};

    }

    return state;
};

export default loaderReducer;