import { actions } from "../constants/actions";

const initState = {currentConflict: null, conflicts: {}};

const conflictReducer = (state = initState, action) => {

    if (action.type === actions.GET_CONFLICTS) {
        state.conflicts = {...action.payload};
        state.currentConflict = {};
        state.currentConflict = {...state.currentConflict};
        return {...state};
    }

    if (action.type === actions.GET_CONFLICT) {
        state.currentConflict = {...action.payload};
        return {...state};
    }

    if (action.type === actions.TYPE_COMMENT) {
        state.currentConflict.commentField = action.payload;
        state.currentConflict = {...state.currentConflict};
        return {...state};
    }

    if (action.type === actions.UPDATE_CONFLICT) {
        state.currentConflict = {...state.currentConflict, ...action.payload};
        return {...state};
    }

    return state;
};

export default conflictReducer;