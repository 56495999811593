export const countries = {
    "AF": {"nameEN": "Afghanistan", "nameFR": "Afghanistan"},
    "AX": {"nameEN": "Aland Islands"},
    "AL": {"nameEN": "Albania", "nameFR": "Albanie"},
    "DZ": {"nameEN": "Algeria", "nameFR": "Algérie"},
    "AS": {"nameEN": "American Samoa"},
    "AD": {"nameEN": "Andorra", "nameFR": "Andorre"},
    "AO": {"nameEN": "Angola", "nameFR": "Angola"},
    "AI": {"nameEN": "Anguilla"},
    "AQ": {"nameEN": "Antarctica"},
    "AG": {"nameEN": "Antigua And Barbuda", "nameFR": "Antigua-et-Barbuda"},
    "AR": {"nameEN": "Argentina", "nameFR": "Argentine"},
    "AM": {"nameEN": "Armenia", "nameFR": "Arménie"},
    "AW": {"nameEN": "Aruba"},
    "AU": {"nameEN": "Australia", "nameFR": "Australie"},
    "AT": {"nameEN": "Austria", "nameFR": "Autriche"},
    "AZ": {"nameEN": "Azerbaijan", "nameFR": "Azerbaïdjan"},
    "BS": {"nameEN": "Bahamas", "nameFR": "Bahamas"},
    "BH": {"nameEN": "Bahrain", "nameFR": "Bahreïn"},
    "BD": {"nameEN": "Bangladesh", "nameFR": "Bangladesh"},
    "BB": {"nameEN": "Barbados", "nameFR": "Barbade"},
    "BY": {"nameEN": "Belarus", "nameFR": "Biélorussie"},
    "BE": {"nameEN": "Belgium", "nameFR": "Belgique"},
    "BZ": {"nameEN": "Belize", "nameFR": "Belize"},
    "BJ": {"nameEN": "Benin", "nameFR": "Bénin"},
    "BM": {"nameEN": "Bermuda"},
    "BT": {"nameEN": "Bhutan", "nameFR": "Bhoutan"},
    "BO": {"nameEN": "Bolivia", "nameFR": "Bolivie"},
    "BA": {"nameEN": "Bosnia And Herzegovina", "nameFR": "Bosnie-Herzégovine"},
    "BW": {"nameEN": "Botswana", "nameFR": "Botswana"},
    "BV": {"nameEN": "Bouvet Island"},
    "BR": {"nameEN": "Brazil", "nameFR": "Brésil"},
    "IO": {"nameEN": "British Indian Ocean Territory"},
    "BN": {"nameEN": "Brunei Darussalam", "nameFR": "Brunei"},
    "BG": {"nameEN": "Bulgaria", "nameFR": "Bulgarie"},
    "BF": {"nameEN": "Burkina Faso", "nameFR": "Burkina Faso"},
    "BI": {"nameEN": "Burundi", "nameFR": "Burundi"},
    "KH": {"nameEN": "Cambodia", "nameFR": "Cambodge"},
    "CM": {"nameEN": "Cameroon", "nameFR": "Cameroun"},
    "CA": {"nameEN": "Canada", "nameFR": "Canada"},
    "CV": {"nameEN": "Cape Verde", "nameFR": "Cap-Vert"},
    "KY": {"nameEN": "Cayman Islands"},
    "CF": {"nameEN": "Central African Republic", "nameFR": "République centrafricaine"},
    "TD": {"nameEN": "Chad", "nameFR": "Tchad"},
    "CL": {"nameEN": "Chile", "nameFR": "Chili"},
    "CN": {"nameEN": "China", "nameFR": "Chine"},
    "CX": {"nameEN": "Christmas Island"},
    "CC": {"nameEN": "Cocos (Keeling) Islands"},
    "CO": {"nameEN": "Colombia", "nameFR": "Colombie"},
    "KM": {"nameEN": "Comoros", "nameFR": "Comores (pays)"},
    "CG": {"nameEN": "Congo", "nameFR": "République du Congo"},
    "CD": {"nameEN": "Congo, Democratic Republic", "nameFR": "République démocratique du Congo"},
    "CK": {"nameEN": "Cook Islands"},
    "CR": {"nameEN": "Costa Rica", "nameFR": "Costa Rica"},
    "CI": {"nameEN": "Cote D'Ivoire", "nameFR": "Côte d'Ivoire"},
    "HR": {"nameEN": "Croatia", "nameFR": "Croatie"},
    "CU": {"nameEN": "Cuba", "nameFR": "Cuba"},
    "CY": {"nameEN": "Cyprus", "nameFR": "Chypre (pays)"},
    "CZ": {"nameEN": "Czech Republic", "nameFR": "Tchéquie"},
    "DK": {"nameEN": "Denmark", "nameFR": "Danemark"},
    "DJ": {"nameEN": "Djibouti", "nameFR": "Djibouti"},
    "DM": {"nameEN": "Dominica", "nameFR": "Dominique"},
    "DO": {"nameEN": "Dominican Republic", "nameFR": "République dominicaine"},
    "EC": {"nameEN": "Ecuador", "nameFR": "Équateur (pays)"},
    "EG": {"nameEN": "Egypt", "nameFR": "Égypte"},
    "SV": {"nameEN": "El Salvador", "nameFR": "Salvador"},
    "GQ": {"nameEN": "Equatorial Guinea", "nameFR": "Guinée équatoriale"},
    "ER": {"nameEN": "Eritrea", "nameFR": "Érythrée"},
    "EE": {"nameEN": "Estonia", "nameFR": "Estonie"},
    "ET": {"nameEN": "Ethiopia", "nameFR": "Éthiopie"},
    "FK": {"nameEN": "Falkland Islands (Malvinas)"},
    "FO": {"nameEN": "Faroe Islands"},
    "FJ": {"nameEN": "Fiji", "nameFR": "Fidji"},
    "FI": {"nameEN": "Finland", "nameFR": "Finlande"},
    "FR": {"nameEN": "France", "nameFR": "France"},
    "GF": {"nameEN": "French Guiana"},
    "PF": {"nameEN": "French Polynesia"},
    "TF": {"nameEN": "French Southern Territories"},
    "GA": {"nameEN": "Gabon", "nameFR": "Gabon"},
    "GM": {"nameEN": "Gambia", "nameFR": "Gambie"},
    "GE": {"nameEN": "Georgia", "nameFR": "Géorgie (pays)"},
    "DE": {"nameEN": "Germany", "nameFR": "Allemagne"},
    "GH": {"nameEN": "Ghana", "nameFR": "Ghana"},
    "GI": {"nameEN": "Gibraltar"},
    "GR": {"nameEN": "Greece", "nameFR": "Grèce"},
    "GL": {"nameEN": "Greenland"},
    "GD": {"nameEN": "Grenada", "nameFR": "Grenade (pays)"},
    "GP": {"nameEN": "Guadeloupe"},
    "GU": {"nameEN": "Guam"},
    "GT": {"nameEN": "Guatemala", "nameFR": "Guatemala"},
    "GG": {"nameEN": "Guernsey"},
    "GN": {"nameEN": "Guinea", "nameFR": "Guinée"},
    "GW": {"nameEN": "Guinea-Bissau", "nameFR": "Guinée-Bissau"},
    "GY": {"nameEN": "Guyana", "nameFR": "Guyana"},
    "HT": {"nameEN": "Haiti", "nameFR": "Haïti"},
    "HM": {"nameEN": "Heard Island & Mcdonald Islands"},
    "VA": {"nameEN": "Holy See (Vatican City State)"},
    "HN": {"nameEN": "Honduras", "nameFR": "Honduras"},
    "HK": {"nameEN": "Hong Kong"},
    "HU": {"nameEN": "Hungary", "nameFR": "Hongrie"},
    "IS": {"nameEN": "Iceland", "nameFR": "Islande"},
    "IN": {"nameEN": "India", "nameFR": "Inde"},
    "ID": {"nameEN": "Indonesia", "nameFR": "Indonésie"},
    "IR": {"nameEN": "Iran, Islamic Republic Of", "nameFR": "Iran"},
    "IQ": {"nameEN": "Iraq", "nameFR": "Irak"},
    "IE": {"nameEN": "Ireland", "nameFR": "Irlande (pays)"},
    "IM": {"nameEN": "Isle Of Man"},
    "IL": {"nameEN": "Israel", "nameFR": "Israël"},
    "IT": {"nameEN": "Italy", "nameFR": "Italie"},
    "JM": {"nameEN": "Jamaica", "nameFR": "Jamaïque"},
    "JP": {"nameEN": "Japan", "nameFR": "Japon"},
    "JE": {"nameEN": "Jersey"},
    "JO": {"nameEN": "Jordan", "nameFR": "Jordanie"},
    "KZ": {"nameEN": "Kazakhstan", "nameFR": "Kazakhstan"},
    "KE": {"nameEN": "Kenya", "nameFR": "Kenya"},
    "KI": {"nameEN": "Kiribati", "nameFR": "Kiribati"},
    "KR": {"nameEN": "Korea", "nameFR": "Corée du Sud"},
    "KW": {"nameEN": "Kuwait", "nameFR": "Koweït"},
    "KG": {"nameEN": "Kyrgyzstan", "nameFR": "Kirghizistan"},
    "LA": {"nameEN": "Lao People's Democratic Republic", "nameFR": "Laos"},
    "LV": {"nameEN": "Latvia", "nameFR": "Lettonie"},
    "LB": {"nameEN": "Lebanon", "nameFR": "Liban"},
    "LS": {"nameEN": "Lesotho", "nameFR": "Lesotho"},
    "LR": {"nameEN": "Liberia", "nameFR": "Liberia"},
    "LY": {"nameEN": "Libyan Arab Jamahiriya", "nameFR": "Libye"},
    "LI": {"nameEN": "Liechtenstein", "nameFR": "Liechtenstein"},
    "LT": {"nameEN": "Lithuania", "nameFR": "Lituanie"},
    "LU": {"nameEN": "Luxembourg", "nameFR": "Luxembourg (pays)"},
    "MO": {"nameEN": "Macao"},
    "MK": {"nameEN": "Macedonia", "nameFR": "Macédoine du Nord"},
    "MG": {"nameEN": "Madagascar", "nameFR": "Madagascar"},
    "MW": {"nameEN": "Malawi", "nameFR": "Malawi"},
    "MY": {"nameEN": "Malaysia", "nameFR": "Malaisie"},
    "MV": {"nameEN": "Maldives", "nameFR": "Maldives"},
    "ML": {"nameEN": "Mali", "nameFR": "Mali"},
    "MT": {"nameEN": "Malta", "nameFR": "Malte"},
    "MH": {"nameEN": "Marshall Islands", "nameFR": "Îles Marshall (pays)"},
    "MQ": {"nameEN": "Martinique"},
    "MR": {"nameEN": "Mauritania", "nameFR": "Mauritanie"},
    "MU": {"nameEN": "Mauritius", "nameFR": "Maurice (pays)"},
    "YT": {"nameEN": "Mayotte"},
    "MX": {"nameEN": "Mexico", "nameFR": "Mexique"},
    "FM": {"nameEN": "Micronesia, Federated States Of", "nameFR": "États fédérés de Micronésie (pays)"},
    "MD": {"nameEN": "Moldova", "nameFR": "Moldavie"},
    "MC": {"nameEN": "Monaco", "nameFR": "Monaco"},
    "MN": {"nameEN": "Mongolia", "nameFR": "Mongolie"},
    "ME": {"nameEN": "Montenegro", "nameFR": "Monténégro"},
    "MS": {"nameEN": "Montserrat"},
    "MA": {"nameEN": "Morocco", "nameFR": "Maroc"},
    "MZ": {"nameEN": "Mozambique", "nameFR": "Mozambique"},
    "MM": {"nameEN": "Myanmar", "nameFR": "Birmanie"},
    "NA": {"nameEN": "Namibia", "nameFR": "Namibie"},
    "NR": {"nameEN": "Nauru", "nameFR": "Nauru"},
    "NP": {"nameEN": "Nepal", "nameFR": "Népal"},
    "NL": {"nameEN": "Netherlands", "nameFR": "Pays-Bas"},
    "AN": {"nameEN": "Netherlands Antilles"},
    "NC": {"nameEN": "New Caledonia"},
    "NZ": {"nameEN": "New Zealand", "nameFR": "Nouvelle-Zélande"},
    "NI": {"nameEN": "Nicaragua", "nameFR": "Nicaragua"},
    "NE": {"nameEN": "Niger", "nameFR": "Niger"},
    "NG": {"nameEN": "Nigeria", "nameFR": "Nigeria"},
    "NU": {"nameEN": "Niue"},
    "NF": {"nameEN": "Norfolk Island"},
    "MP": {"nameEN": "Northern Mariana Islands"},
    "NO": {"nameEN": "Norway", "nameFR": "Norvège"},
    "OM": {"nameEN": "Oman", "nameFR": "Oman"},
    "PK": {"nameEN": "Pakistan", "nameFR": "Pakistan"},
    "PW": {"nameEN": "Palau", "nameFR": "Palaos"},
    "PS": {"nameEN": "Palestinian Territory, Occupied"},
    "PA": {"nameEN": "Panama", "nameFR": "Panama"},
    "PG": {"nameEN": "Papua New Guinea", "nameFR": "Papouasie-Nouvelle-Guinée"},
    "PY": {"nameEN": "Paraguay", "nameFR": "Paraguay"},
    "PE": {"nameEN": "Peru", "nameFR": "Pérou"},
    "PH": {"nameEN": "Philippines", "nameFR": "Philippines"},
    "PN": {"nameEN": "Pitcairn"},
    "PL": {"nameEN": "Poland", "nameFR": "Pologne"},
    "PT": {"nameEN": "Portugal", "nameFR": "Portugal"},
    "PR": {"nameEN": "Puerto Rico"},
    "QA": {"nameEN": "Qatar", "nameFR": "Qatar"},
    "RE": {"nameEN": "Reunion"},
    "RO": {"nameEN": "Romania", "nameFR": "Roumanie"},
    "RU": {"nameEN": "Russian Federation", "nameFR": "Russie"},
    "RW": {"nameEN": "Rwanda", "nameFR": "Rwanda"},
    "BL": {"nameEN": "Saint Barthelemy"},
    "SH": {"nameEN": "Saint Helena"},
    "KN": {"nameEN": "Saint Kitts And Nevis", "nameFR": "Saint-Christophe-et-Niévès"},
    "LC": {"nameEN": "Saint Lucia", "nameFR": "Sainte-Lucie"},
    "MF": {"nameEN": "Saint Martin"},
    "PM": {"nameEN": "Saint Pierre And Miquelon"},
    "VC": {"nameEN": "Saint Vincent And Grenadines", "nameFR": "Saint-Vincent-et-les-Grenadines"},
    "WS": {"nameEN": "Samoa", "nameFR": "Samoa"},
    "SM": {"nameEN": "San Marino", "nameFR": "Saint-Marin"},
    "ST": {"nameEN": "Sao Tome And Principe", "nameFR": "Sao Tomé-et-Principe"},
    "SA": {"nameEN": "Saudi Arabia", "nameFR": "Arabie saoudite"},
    "SN": {"nameEN": "Senegal", "nameFR": "Sénégal"},
    "RS": {"nameEN": "Serbia", "nameFR": "Serbie"},
    "SC": {"nameEN": "Seychelles", "nameFR": "Seychelles"},
    "SL": {"nameEN": "Sierra Leone", "nameFR": "Sierra Leone"},
    "SG": {"nameEN": "Singapore", "nameFR": "Singapour"},
    "SK": {"nameEN": "Slovakia", "nameFR": "Slovaquie"},
    "SI": {"nameEN": "Slovenia", "nameFR": "Slovénie"},
    "SB": {"nameEN": "Solomon Islands", "nameFR": "Salomon"},
    "SO": {"nameEN": "Somalia", "nameFR": "Somalie"},
    "ZA": {"nameEN": "South Africa", "nameFR": "Afrique du Sud"},
    "GS": {"nameEN": "South Georgia And Sandwich Isl."},
    "ES": {"nameEN": "Spain", "nameFR": "Espagne"},
    "LK": {"nameEN": "Sri Lanka", "nameFR": "Sri Lanka"},
    "SD": {"nameEN": "Sudan", "nameFR": "Soudan"},
    "SR": {"nameEN": "Suriname", "nameFR": "Suriname"},
    "SJ": {"nameEN": "Svalbard And Jan Mayen"},
    "SZ": {"nameEN": "Swaziland", "nameFR": "Eswatini"},
    "SE": {"nameEN": "Sweden", "nameFR": "Suède"},
    "CH": {"nameEN": "Switzerland", "nameFR": "Suisse"},
    "SY": {"nameEN": "Syrian Arab Republic", "nameFR": "Syrie"},
    "TW": {"nameEN": "Taiwan"},
    "TJ": {"nameEN": "Tajikistan", "nameFR": "Tadjikistan"},
    "TZ": {"nameEN": "Tanzania", "nameFR": "Tanzanie"},
    "TH": {"nameEN": "Thailand", "nameFR": "Thaïlande"},
    "TL": {"nameEN": "Timor-Leste", "nameFR": "Timor oriental"},
    "TG": {"nameEN": "Togo", "nameFR": "Togo"},
    "TK": {"nameEN": "Tokelau"},
    "TO": {"nameEN": "Tonga", "nameFR": "Tonga"},
    "TT": {"nameEN": "Trinidad And Tobago", "nameFR": "Trinité-et-Tobago"},
    "TN": {"nameEN": "Tunisia", "nameFR": "Tunisie"},
    "TR": {"nameEN": "Turkey", "nameFR": "Turquie"},
    "TM": {"nameEN": "Turkmenistan", "nameFR": "Turkménistan"},
    "TC": {"nameEN": "Turks And Caicos Islands"},
    "TV": {"nameEN": "Tuvalu", "nameFR": "Tuvalu"},
    "UG": {"nameEN": "Uganda", "nameFR": "Ouganda"},
    "UA": {"nameEN": "Ukraine", "nameFR": "Ukraine"},
    "AE": {"nameEN": "United Arab Emirates", "nameFR": "Émirats arabes unis"},
    "GB": {"nameEN": "United Kingdom", "nameFR": "Royaume-Uni"},
    "US": {"nameEN": "United States", "nameFR": "États-Unis"},
    "UM": {"nameEN": "United States Outlying Islands"},
    "UY": {"nameEN": "Uruguay", "nameFR": "Uruguay"},
    "UZ": {"nameEN": "Uzbekistan", "nameFR": "Ouzbékistan"},
    "VU": {"nameEN": "Vanuatu", "nameFR": "Vanuatu"},
    "VE": {"nameEN": "Venezuela", "nameFR": "Venezuela"},
    "VN": {"nameEN": "Viet Nam", "nameFR": "Viêt Nam"},
    "VG": {"nameEN": "Virgin Islands, British"},
    "VI": {"nameEN": "Virgin Islands, U.S."},
    "WF": {"nameEN": "Wallis And Futuna"},
    "EH": {"nameEN": "Western Sahara"},
    "YE": {"nameEN": "Yemen", "nameFR": "Yémen"},
    "ZM": {"nameEN": "Zambia", "nameFR": "Zambie"},
    "ZW": {"nameEN": "Zimbabwe", "nameFR": "Zimbabwe"}
};
