import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TableButton extends Component {

    render() {

        let buttonStyle = "table-button-container";

        if (this.props.danger) {

            buttonStyle += " danger";

        }

        if (this.props.disabled) {
            buttonStyle = "disabled-form-button";

        }

        return (
            <div onClick={ this.props.onPress } className={ buttonStyle } data-toggle="tooltip" data-placement="right"
                 title={ this.props.tooltip }>
                <FontAwesomeIcon icon={ this.props.icon } className="table-button-icon"/>
            </div>
        );
    }

}

export default TableButton;