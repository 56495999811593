import React, { Component } from  "react"

class Detail extends Component {

    render() {
        return(
            <div>
                <strong>{ this.props.title }</strong>
                <p>{ this.props.value }</p>
            </div>
        )
    }

}

export default Detail