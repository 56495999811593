import React, { Component } from "react";
import { connect } from "react-redux";
import { strings } from "../../../resources/strings";
import TopicSection from "../../common/TopicSection";
import Section from "../../common/Section";
import { Fields } from "../../common/Fields";
import FormButton from "../../common/buttons/FormButton";
import Pagination from "../../common/Pagination";
import Table from "../../common/Table";
import { getFormValues, reduxForm } from "redux-form";
import { formUtils } from "../../../utils/formUtils";
import { exportData, getLanguages, getRequests, setPagination } from "../../../actions";
import { appUtils } from "../../../utils/appUtils";
import { paginationTypes } from "../../../constants/app";

class RequestsPage extends Component {

    onFormSubmit = (formValues) => {
        this.props.getRequests(formValues);
        this.props.setPagination(paginationTypes.REQUESTS, 1);
    };

    componentDidMount() {
        this.props.getLanguages();
        this.props.getRequests(this.props.formValues, this.props.page);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.page !== this.props.page) {
            this.props.getRequests(this.props.formValues, this.props.page);
        }
    }

    setCurrentPage = (page) => {
        this.props.setPagination(paginationTypes.REQUESTS, page);
    };

    renderForm = () => {

        return (
            <form
                onSubmit={ this.props.handleSubmit(this.onFormSubmit) }
            >
                { Fields.requestId }
                { Fields.languageFrom }
                { Fields.languageTo }
                { Fields.requestStatus }
                { Fields.requestsOrderBy }
                <FormButton
                    onPress={ this.onFormSubmit }
                    className="margin-bottom-m margin-top-m"
                    title={ strings.filter }
                />
            </form>
        );
    };

    renderPagination = () => {
        const {requests} = this.props;
        if (requests) {
            return (
                <Pagination
                    currentPage={ this.props.page }
                    total={ requests.total }
                    setCurrentPage={ this.setCurrentPage }
                />
            );
        }
    };

    renderRequests = () => {

        const {requests} = this.props;

        return <Table
            columns={ [strings.ID, strings.client, strings.advisor, strings.languages, strings.createdOn, strings.status, strings.action] }
            data={ appUtils.parseRequests(requests.data) }
        />;
    };

    render() {
        return (
            <div className="page-container flex-column flex-fill">

                <div className="row p-0">
                    <div className="col-10">
                        <TopicSection
                            title={ strings.requests }
                            buttons={ [
                                {
                                    title: strings.exportAllRequests,
                                    action: () => {
                                        this.props.exportData("requests");
                                    }
                                }
                            ] }
                        />
                        { this.renderRequests() }
                        { this.renderPagination() }
                    </div>
                    <div className="col-2">
                        <Section title={ strings.filters } first/>
                        { this.renderForm() }
                    </div>
                </div>
            </div>
        );
    }
}

const requestsFilterForm = reduxForm({form: "requestsFilterForm", validate: formUtils.validate})(RequestsPage);
const mapStateToProps = state => {
    return {
        requests: state.requests.requests,
        formValues: getFormValues("requestsFilterForm")(state),
        page: state.pagination[paginationTypes.REQUESTS]
    };
};

export default connect(mapStateToProps, {getLanguages, getRequests, setPagination, exportData})(requestsFilterForm);