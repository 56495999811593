import { hideGlobalLoader, showGlobalLoader } from "./loaderActions";
import { createAxios as axios } from "../api/server";
import { actions } from "../constants/actions";
import { appUtils } from "../utils/appUtils";

export const getStatistics = () => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/statistics", {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.GET_STATISTICS, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());

};