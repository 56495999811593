import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import DefaultButton from "./buttons/DefaultButton";
import { hideDialog } from "../../actions";

class Dialog extends React.Component {

    renderPrimaryButton = () => {

        const {primaryButton} = this.props.dialog;

        if (!primaryButton) {
            return;
        }

        const action = primaryButton.close ? this.props.hideDialog : primaryButton.action;
        const danger = primaryButton.danger;
        const buttonStyle = this.props.secoundButton ? "d-flex flex-fill col-6" : "d-flex flex-fill mr-auto ml-auto col-6";

        if (primaryButton) {
            return (
                <div className={ buttonStyle }>
                    <DefaultButton danger={ danger } title={ primaryButton.title } onPress={ action }/>
                </div>
            );
        }
    };

    renderSecondaryButton = () => {

        const {secondaryButton} = this.props.dialog;

        if (!secondaryButton) {
            return;
        }

        const action = secondaryButton.close ? this.props.hideDialog : secondaryButton.action;
        const danger = secondaryButton.danger;

        if (secondaryButton) {
            return (
                <div className="d-flex flex-fill col-6">
                    <DefaultButton danger={ danger } title={ secondaryButton.title } onPress={ action }/>
                </div>);
        }
    };

    render() {

        //TODO Complete Dialog

        if (this.props.dialog && this.props.dialog.showDialog) {
            return ReactDOM.createPortal(
                <Fragment>
                    <div className="eclipse flex-fill">
                        <div className="dialog-small-container ">
                            <h1 className="text-center dialog-title">{ this.props.dialog.title }</h1>
                            <p className="dialog-error-message mb-0 ">{ this.props.dialog.message }</p>
                            <div className="dialog-button-container row">
                                { this.renderPrimaryButton() }
                                { this.renderSecondaryButton() }
                            </div>
                        </div>
                    </div>
                </Fragment>
                ,
                document.querySelector("#dialog")
            );
        }
        return <Fragment/>;
    }
}

const mapStateToProps = state => {
    return {dialog: state.dialog.dialogSmall};
};

export default connect(mapStateToProps, {hideDialog})(Dialog);