import React, { Component, Fragment } from "react";
import NavigationRoutes from "./NavigationRoutes";
import ProgressDialog from "./common/ProgressDialog";
import HeaderBar from "./common/HeaderBar";
import Dialog from "./common/Dialog";
import DialogLarge from "./common/DialogLarge"

class App extends Component {

    render() {

        return (
            <Fragment>
                <HeaderBar/>
                <div className="container-fluid row flex-nowrap p-0 m-0 full-height">
                    <div className="container-fluid p-0">
                    <NavigationRoutes/>
                    </div>
                </div>
                <ProgressDialog/>
                <DialogLarge/>
                <Dialog/>
            </Fragment>
        );
    }
}

export default App;