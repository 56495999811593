import {Route} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";
import { appUtils } from "../../utils/appUtils";
import navigationUtils from "../../utils/navigationUtils";
import { pagesPaths } from "../../constants/pagesPaths";

class PrivateRoute extends React.Component{

    componentDidMount() {
        if (appUtils.isEpmtyObject(this.props.admin)) {
            navigationUtils.navigate(pagesPaths.ADMIN)
        }
    }

    render() {
        return(
            <Route path={this.props.path} exact component={this.props.component}/>
        )
    }
}

const mapStateToProps = (state) => {
    return {admin: state.admin.myAccount}
};

export default connect(mapStateToProps)(PrivateRoute)