export const strings = {
    users: "Users",
    allRightsReserved: "All rights reserved",
    viewAllConflicts: "View All Conflicts",
    ID: "ID",
    userId: "User ID",
    name: "Name",
    type: "Type",
    email: "Email",
    action: "Action",
    completedRequests: "Completed Requests",
    clients: "Clients",
    advisors: "Advisors",
    totalRequests: "Total Requests",
    turnover: "Turnover",
    profit: "Profit",
    statistics: "Statistics",
    latestConflicts: "Latest Conflicts",
    requestId: "Request ID",
    initiator: "Initiator",
    initiatorType: "Initiator Type",
    status: "Status",
    date: "Date",
    hi: "Hi, ",
    signOut: "Sign Out",
    signIn: "Sign In",
    password: "Password",
    emailErrorMessage: "Please enter a valid email!",
    passwordErrorMessage: "Your password must have at least 8 character!",
    dashboard: "Dashboard",
    filters: "Filters",
    filter: "Filter",
    firstName: "First Name",
    lastName: "Last Name",
    orderBy: "Order By",
    advisor: "Advisor",
    advisorStatus: "Advisor Status",
    client: "Client",
    expat: "Expat",
    both: "Both",
    error: "Error",
    close: "Close",
    IDDesc: "ID Desc",
    firstNameDesc: "First Name Desc",
    lastNameDesc: "Last Name Desc",
    emailDesc: "Email Desc",
    registeredOn: "Registered On",
    location: "Location",
    IBAN: "IBAN",
    IBANHolderName: "IBAN Holder Name",
    about: "About",
    deleteUser: "Delete User",
    deleteUserMessage: "Are you sure to delete this user?",
    cancel: "Cancel",
    no: "No",
    yes: "Yes",
    warning: "Warning",
    documents: "Documents",
    languages: "Languages",
    expertise: "Service",
    expertiseCategory: "Need",
    expertiseCategories: "Needs",
    language: "Language",
    transactions: "Transactions",
    requests: "Requests",
    certificates: "Certificates",
    ADVICE: "Advice",
    TRANSLATION: "Translation / Interpretation",
    BOTH: "Both",
    GENERAL: "General",
    RELOCATION: "Relocation",
    UN_INT_ORG: "UN & Organizations",
    EMPLOYMENT: "Employment",
    EDUCATION: "Education",
    HEALTH: "Health",
    VEHICLES: "Vehicles",
    FAMILY_SOCIAL: "Family/Social",
    RENT_PROPERTY: "Rent/Property",
    INSURANCE: "Insurance",
    FINANCE_TAXES: "Finance/Taxes",
    LEGAL: "Legal",
    EVENT: "Event",
    BUSINESS_COMPANY: "Business/Company",
    IT_SERVICES: "IT Services",
    TRANSLATOR: "Advisor",
    CLIENT: "Client",
    NOT_SELECTED: "Not Selected",
    notSet: "Not Set",
    noItems: "No items found",
    noRequests: "There is no requests for this user",
    noTransactions: "There is no transactions yet",
    languageFrom: "Language From",
    languageTo: "Language To",
    createdOn: "Created On",
    createdOnDesc: "Created On Desc",
    PENDING: "Waiting for Advisor",
    PENDING_PAYMENT: "Pending Client Payment",
    PAID: "Scheduled and Paid",
    IN_PROGRESS: "In Progress",
    COMPLETED: "Completed",
    CANCELED: "Canceled",
    REFUSED: "Refused",
    all: "All",
    startDate: "Start Date",
    now: "Now",
    duration: "Duration",
    description: "Description",
    min: "Min",
    messages: "Messages",
    extends: "Extends",
    total: "Total",
    time: "Time",
    price: "Price",
    CHF: "CHF",
    request: "Request",
    noMessages: "There is no messages",
    pendingPayments: "Pending Payments",
    advisorsPendingPayments: "Advisors Pending Payments",
    notPaidRequests: "Not Paid Requests",
    totalProfit: "Total Profit",
    advisorName: "Advisor Name",
    advisorId: "Advisor ID",
    advisorProfit: "Advisor Profit",
    markRequestsAsPaid: "Mark requests as paid",
    conflicts: "Conflicts",
    pendingPayment: "Pending Payment",
    success: "Success",
    noBankAccountMessage: "This advisor does not provide a Bank Account",
    ok: "Ok",
    successfulMarkedRequestsMessage: "You have marked the selected requests as paid",
    noRequestsSelectedMessage: "There are no selected requests",
    resolve: "Resolve",
    viewRequest: "View Request",
    against: "Against",
    closeConflict: "Close Conflict",
    comment: "Comment",
    writeComment: "Write Comment",
    resolveConflict: "Resolve Conflict",
    exportCSV: "Export CSV",
    view: "View",
    pageNotFound: "Page Not Found",
    code404: "404",
    chooseRequests: "Choose Requests",
    send: "Send",
    of: "Of",
    settings: "Settings",
    personalDetails: "Personal Details",
    changePassword: "Change Password",
    edit: "Update",
    repeatPassword: "Repeat Password",
    oldPassword: "Old Password",
    passwordsMismatch: "Password mismatch!",
    noCommentMessage: "You can not send an empty comment!",
    successfulChangedPasswordMessage: "Your password has been changed successfully",
    successfulEditedDetailsMessage: "Your personal details has been updated successfully",
    administrators: "Administrators",
    lastLogin: "Last Login",
    rights: "Rights",
    noLogsYet: "No Logs Yet",
    addNewAdministrator: "Create Basic Admin",
    createAdmin: "Create Basic Admin",
    deleteAdmin: "Delete Admin",
    create: "Create",
    delete: "Delete",
    download: "Download",
    deleteAdminMessage: "Are you sure to delete this admin?",
    updateAdminErrorMessage: "You did not have permissions to edit this admin",
    back: "Back",
    successfulDeletedAdminMessage: "Admin has been deleted successfully",
    successfulCreatedAdminMessage: "Admin has been created successfully",
    successfulDeletedUserMessage: "User has been deleted successfully",
    networkErrorMessage: "Network Error. Try Again Later!",
    deleted: "Deleted",
    blockUser: "Block User",
    unblockUser: "Unblock User",
    blockUserMessage: "Are you sure to block this user?",
    blockUserRefundNote: "Please be aware that this user has already paid request/s that you need to refund",
    requestsWaitingRefund: "Requests Waiting Refund",
    amount: "Amount",
    block: "Block",
    blockedUsers: "Blocked Users",
    unblockUserMessage: "Are you sure to unblock this user?",
    unblock: "Unblock",
    extendPaymentId: "Extend Payment ID",
    requestPaymentId: "Request Payment ID",
    startTime: "Start Time",
    stripePaymentId: "Stripe Payment ID",
    extend: "Extend",
    paymentDetails: "Payment Details",
    successfulBlockedUser: "User has been blocked successfully",
    successfulUnblockedUser: "User has been unblocked successfully",
    noDocuments: "There is no documents uploaded",
    noLanguages: "There is no languages added",
    noExtends: "The request has not been extended",
    noBlockedUsers: "No blocked users",
    noTotal: "No money received from this request",
    SELF_EMPLOYED: "Self Employed",
    COMPANY: "Company",
    EMPLOYEE: "Employee",
    OTHER: "Other",
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday",
    rejectReason: "Reject Reason",
    rejectReasonError: "Please enter a reject reason",
    address: "Address",
    workSchedule: "Work Schedule",
    STUDENT: "Student",
    RETIRED: "Retired",
    UNEMPLOYED_WITHOUT_REVENUE: "Unemployed without revenue",
    UNEMPLOYMENT_INSURANCE: "Unemployment insurance",
    HOSPICE: "Hospice",
    basedInSwitzerland: "Based in Switzerland",
    partFullTime: "Part / Full Time",
    annexProof: "Annex proof",
    annexRCExtract: "Annex RC extract",
    fullTime: "Full time",
    street: "Street",
    zipCode: "Zip Code",
    city: "City/Town",
    country: "Country",
    document: "Document",
    statute: "Statute",
    accountStatus: "Account Status",
    other: "Other",
    ACCOUNT_PENDING: "Pending",
    ACCOUNT_PUBLISHED: "Published",
    ACCOUNT_IN_REVIEW: "Under Review",
    ACCOUNT_REJECTED: "Rejected",
    changeStatus: "Change Account Status",
    active: "Active",
    apply: "Apply",
    accountStatusDescription: "Specify advisor account status",
    IDENTITY: "ID",
    CV: "CV",
    SWISS_PERMIT: "Swiss Permit",
    notActive: "Not Active",
    exportAllClients: "Export All Clients",
    exportAllAdvisors: "Export All Advisors",
    exportAllRequests: "Export All Requests",
    exportAllTransactions: "Export All Transactions",
    somethingWentWrong: "Something went wrong, please try again later or contact the IT department",
    feedback: "Feedback",
    noFeedback: "There is no feedback left for this request yet",


    // REGIONS
    ARGOVIE: "Argovie/Aargau",
    APPENZELL: "Appenzell",
    BERN: "Berne/Bern",
    BASEL: "Bâle/Basel",
    FRIBOURG: "Fribourg/Freiburg",
    GENEVA: "Genève/Geneva/Genf",
    FRANCE_VOISINE: "France Voisine",
    GLARIS: "Glaris",
    GRISONS: "Grisons/Graubünden",
    JURA: "Jura",
    LUCERNE: "Lucerne/Luzern",
    NEUCHATEL: "Neuchâtel/Neuenburg",
    NIDWALD: "Nidwald/Obwald",
    SAINT_GALL: "Saint-Gall/Sankt Gallen",
    SCHAFFHOUSE: "Schaffhouse/Schaffhausen",
    SOLEURE: "Soleure/Solothurn",
    SCHWYZ: "Schwyz",
    THURGOVIE: "Thurgovie/Thurgau",
    TESSIN: "Tessin/Ticino",
    URI: "Uri",
    VAUD: "Vaud/Waadt",
    VALAIS: "Valais/Wallis",
    ZOUG: "Zoug",
    ZURICH: "Zurich"
};
