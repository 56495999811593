import { actions } from "../constants/actions";

const initState = {};

const paginationReducer = (state = {...initState}, action) => {
    if (action.type === actions.SET_PAGINATION) {

        state[action.payload.type] = action.payload.page;
        return {...state};
    }

    if (action.type === actions.CLEAR_PAGINATION) {

        state = {};
        return {...state};
    }

    return state;
};

export default paginationReducer;