import React, { Component, Fragment } from "react";
import TopicSection from "../../common/TopicSection";
import { strings } from "../../../resources/strings";
import Section from "../../common/Section";
import PersonalDetails from "./PersonalDetails";
import ChangePasswords from "./ChangePassword";
import { connect } from "react-redux";
import { adminRights } from "../../../constants/app";
import { getAdmins } from "../../../actions";
import Table from "../../common/Table";
import { appUtils } from "../../../utils/appUtils";
import navigationUtils from "../../../utils/navigationUtils";
import { pagesPaths } from "../../../constants/pagesPaths";

class SettingsPage extends Component {

    componentDidMount() {
        const {myAccount} = this.props.admin;

        if (myAccount.rights === adminRights.MASTER) {
            this.props.getAdmins();
        }
    }

    renderAdministratorsList = () => {
        const {myAccount} = this.props.admin;

        if (myAccount.rights === adminRights.MASTER) {
            return (
                <Fragment>
                    <Section title={ strings.administrators }
                             buttonTitle={ strings.addNewAdministrator }
                             onSectionButtonPress={ () => {
                                 navigationUtils.navigate(pagesPaths.CREATE_ADMIN);
                             } }/>
                    { this.renderAdminsTable() }
                </Fragment>
            );
        }
    };

    renderAdminsTable = () => {
        const {admins} = this.props.admin;

        return <Table
            columns={ [strings.ID, strings.name, strings.email, strings.lastLogin, strings.rights, strings.action] }
            data={ appUtils.parseAdmins(admins) }
        />;
    };

    render() {

        const {myAccount} = this.props.admin;

        if (appUtils.isEpmtyObject(myAccount)) {
            return <Fragment/>;
        }

        return (
            <div className="page-container flex-column flex-fill">
                <TopicSection title={ strings.settings }/>
                <div className="row ">
                    <div className="col-6">
                        <PersonalDetails/>
                    </div>
                    <div className="col-6">
                        <ChangePasswords/>
                    </div>
                </div>
                <div>
                    { this.renderAdministratorsList() }
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {admin: state.admin};

};

export default connect(mapStateToProps, {getAdmins})(SettingsPage);