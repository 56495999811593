import { Router, Route, Switch, Redirect } from "react-router-dom";
import React, { Component } from "react";
import Page404 from "./common/Page404";
import SignInPage from "./pages/signIn/SignInPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import history from "../history";
import PrivateRoute from "./common/PrivateRoute";
import SideBar from "./common/SideBar";
import UsersPage from "./pages/users/UsersPage";
import Footer from "./common/Footer";
import UserPreviewPage from "./pages/users/UserPreviewPage";
import RequestsPage from "./pages/requests/RequestsPage";
import RequestPreviewPage from "./pages/requests/RequestPreviewPage";
import PendingPaymentsPage from "./pages/pendingPayments/PendingPaymentsPage";
import ConflictsPage from "./pages/conflicts/ConflictsPage";
import ConflictPreviewPage from "./pages/conflicts/ConflictPreviewPage";
import SettingsPage from "./pages/settings/SettingsPage";
import AddEditAdminPage from "./pages/settings/AddEditAdminPage";
import BlockedUsers from "./pages/blockedUsers/BlockedUsersPage";
import { pagesPaths } from "../constants/pagesPaths";

class NavigationRoutes extends Component {

    render() {
        return (
            <div className="d-flex flex-fill p-0 main-container full-height">
                <Router history={ history }>
                    <SideBar/>
                    <div className="flex-column route-container full-height flex-fill">
                        <Switch>
                            <Route path={ pagesPaths.ADMIN } exact component={ SignInPage }/>
                            <PrivateRoute path={ pagesPaths.DASHBOARD } exact component={ DashboardPage }/>
                            <PrivateRoute path={ pagesPaths.USERS } exact component={ UsersPage }/>
                            <PrivateRoute path={ pagesPaths.USERS + "/:id" } exact component={ UserPreviewPage }/>
                            <PrivateRoute path={ pagesPaths.REQUESTS } exact component={ RequestsPage }/>
                            <PrivateRoute path={ pagesPaths.REQUESTS + "/:id" } exact component={ RequestPreviewPage }/>
                            <PrivateRoute path={ pagesPaths.PENDING_PAYMENTS } exact component={ PendingPaymentsPage }/>
                            <PrivateRoute path={ pagesPaths.CONFLICTS } exact component={ ConflictsPage }/>
                            <PrivateRoute path={ pagesPaths.CONFLICTS + "/:id" } exact component={ ConflictPreviewPage }/>
                            <PrivateRoute path={ pagesPaths.SETTINGS } exact component={ SettingsPage }/>
                            <PrivateRoute path={ pagesPaths.SETTINGS + "/:id" } exact component={ AddEditAdminPage }/>
                            <PrivateRoute path={ pagesPaths.BLOCKED_USERS } exact component={ BlockedUsers }/>
                            <Route path={ pagesPaths.PAGE_NOT_FOUND } exact component={ Page404 }/>
                            <Redirect to={ pagesPaths.PAGE_NOT_FOUND }/>
                        </Switch>
                        <Footer/>
                    </div>
                </Router>
            </div>
        );
    }
}

export default NavigationRoutes;


