import React, { Component } from "react";
import DefaultButton from "./buttons/DefaultButton";

class Section extends Component {

    renderButton = () => {
        if (this.props.buttonTitle) {
            return (
                <div className="w-auto text-nowrap p-0 align-self-start section-button-container ml-auto col-2">
                    <DefaultButton onPress={ this.props.onSectionButtonPress } title={ this.props.buttonTitle }/>
                </div>
            );
        }
    };

    renderView = () => {
        if (this.props.view) {
            return (
                <div className="w-auto text-nowrap ml-auto">
                    { this.props.view }
                </div>
            );
        }
    };

    render() {

        let sectionStyle = this.props.first ? "first-section p-0" : "next-section p-0";

        return (
            <div className={ sectionStyle }>
                <div className="align-items-end d-flex">
                    <h2>{ this.props.title }</h2>
                    { this.renderView() }
                    { this.renderButton() }
                </div>
                <hr/>
            </div>
        );
    }

}

export default Section;
