import React, { Component } from "react";

class FormButton extends Component {
    render() {

        let className = "primary-button-container flex-fill";
        if (this.props.className) {
            className += " " + this.props.className;
        }

        return (
            <button className={className}>
                <span className="primary-button-text">{ this.props.title }</span>
            </button>
        );
    }
}

export default FormButton;