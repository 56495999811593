import React, { Component, Fragment } from "react";
import { appUtils } from "../../../utils/appUtils";
import Table from "../../common/Table";
import { strings } from "../../../resources/strings";
import { getStatistics, getConflicts, setPagination } from "../../../actions";
import { connect } from "react-redux";
import TopicSection from "../../common/TopicSection";
import Pagination from "../../common/Pagination";
import { ITEMS_PER_PAGE, paginationTypes } from "../../../constants/app";

class ConflictsPage extends Component {

    componentDidMount() {
        this.props.getConflicts(this.props.page, ITEMS_PER_PAGE);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.page !== this.props.page) {
            this.props.getConflicts(this.props.page, ITEMS_PER_PAGE);
        }
    }

    setCurrentPage = (page) => {
        this.props.setPagination(paginationTypes.CONFLICTS, page);
    };

    renderConflicts = () => {

        const conflicts = appUtils.parseConflicts(this.props.conflicts.data);

        return <Table
            columns={ [strings.ID, strings.requestId, strings.initiator, strings.initiatorType, strings.status, strings.date, strings.action] }
            data={ conflicts }
        />;
    };

    renderPagination = () => {
        const {conflicts} = this.props;
        if (conflicts) {
            return (
                <Pagination
                    currentPage={ this.props.page }
                    total={ conflicts.total }
                    setCurrentPage={ this.setCurrentPage }
                />
            );
        }
    };

    render() {

        const {conflicts} = this.props;

        if (appUtils.isEpmtyObject(conflicts)) {
            return <Fragment/>;
        }

        return (
            <div className="page-container flex-column flex-fill">
                <div className="flex-column flex-fill">
                    <TopicSection title={ strings.conflicts }/>
                    { this.renderConflicts() }
                    { this.renderPagination() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        conflicts: state.conflicts.conflicts,
        page: state.pagination[paginationTypes.CONFLICTS]
    };
};

export default connect(mapStateToProps, {getConflicts, getStatistics, setPagination})(ConflictsPage);