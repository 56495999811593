import { strings } from "../resources/strings";
import { faCheck, faCoins, faTasks, faUsers } from "@fortawesome/free-solid-svg-icons";

export const ITEMS_PER_PAGE = 10;

export const PLATFORM_FEE = 1.3;

export const userType = {
    ADVISOR: "TRANSLATOR",
    CLIENT: "CLIENT"
};

export const ORDER_USERS_OPTIONS = [
    {value: "id DESC", name: strings.IDDesc},
    {value: "id", name: strings.ID},
    {value: "first_name DESC", name: strings.firstNameDesc},
    {value: "first_name", name: strings.firstName},
    {value: "last_name DESC", name: strings.lastNameDesc},
    {value: "last_name", name: strings.lastName},
    {value: "email DESC", name: strings.emailDesc},
    {value: "email", name: strings.email}
];

export const ORDER_REQUESTS_OPTIONS = [
    {value: "id DESC", name: strings.IDDesc},
    {value: "id", name: strings.ID},
    {value: "created_at DESC", name: strings.createdOnDesc},
    {value: "created_at", name: strings.createdOn}
];

export const REQUEST_STATUSES = [
    {value: "", name: strings.all},
    {value: "1", name: strings.PENDING_PAYMENT},
    {value: "2", name: strings.IN_PROGRESS},
    {value: "3", name: strings.PENDING},
    {value: "4", name: strings.PAID},
    {value: "5", name: strings.COMPLETED},
    {value: "6", name: strings.REFUSED},
    {value: "7", name: strings.CANCELED}
];

export const durationStatuses = {
    COMPLETED: "COMPLETED",
    IN_PROGRESS: "IN_PROGRESS",
    PAID: "PAID"
};

export const USER_TYPE_OPTIONS = [
    {value: null, name: strings.both},
    {value: userType.ADVISOR, name: strings.advisor},
    {value: userType.CLIENT, name: strings.client}
];

export const ACCOUNT_STATUS_OPTIONS = [
    {value: "PUBLISHED", name: strings.ACCOUNT_PUBLISHED},
    {value: "REJECTED", name: strings.ACCOUNT_REJECTED}
];

export const ALL_ACCOUNT_STATUS_OPTIONS = [
    {value: null, name: strings.all},
    {value: "PENDING", name: strings.ACCOUNT_PENDING},
    {value: "IN_REVIEW", name: strings.ACCOUNT_IN_REVIEW},
    {value: "PUBLISHED", name: strings.ACCOUNT_PUBLISHED},
    {value: "REJECTED", name: strings.ACCOUNT_REJECTED}
];

export const statistics = {
    CLIENTS: {
        name: strings.clients,
        color: "blue",
        icon: faUsers
    },

    ADVISORS: {
        name: strings.advisors,
        color: "brown",
        icon: faUsers
    },

    COMPLETED_REQUESTS: {
        name: strings.completedRequests,
        color: "green",
        icon: faCheck
    },

    TOTAL_REQUESTS: {
        name: strings.totalRequests,
        color: "aqua",
        icon: faTasks
    },

    TURNOVER: {
        name: strings.turnover,
        color: "dark-brown",
        icon: faCoins,
        currency: strings.CHF
    },

    PROFIT: {
        name: strings.profit,
        color: "primary-blue",
        icon: faCoins,
        currency: strings.CHF
    }
};

export const ADVISOR_STATUS_TYPES = {
    SELF_EMPLOYED: "SELF_EMPLOYED",
    COMPANY: "COMPANY",
    EMPLOYEE: "EMPLOYEE",
    OTHER: "OTHER"
};

export const ADVISOR_STATUTES = {
    STUDENT: "Student",
    RETIRED: "Retired",
    UNEMPLOYED_WITHOUT_REVENU: "Unemployed without revenu",
    UNEMPLOYMENT_INSURANCE: "Unemployment insurance",
    HOSPICE: "Hospice",
    OTHER: "OTHER"
};

export const AccountStatuses = {
    PENDING: "PENDING",
    PUBLISHED: "PUBLISHED",
    IN_REVIEW: "IN_REVIEW",
    REJECTED: "REJECTED"
};

export const tableActions = {
    VIEW: "view",
    DELETE: "delete",
    PAYMENT: "payment",
    STRIPE: "stripe",
    DOWNLOAD: "download"
};

export const contactProviders = {
    PHONE: "Phone",
    SKYPE: "Skype",
    VIBER: "Viber",
    WHATS_APP: "WhatsApp",
    WE_CHAT: "WeChat",
    OTHER: "Other"
};

export const defaultAvatarUrl = "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";

export const userTypes = {
    ADVISOR: "TRANSLATOR",
    CLIENT: "CLIENT",
    NOT_SELECTED: "NOT_SELECTED"
};

export const adminRights = {
    MASTER: "MASTER",
    NORMAL: "NORMAL"
};

export const conflictStatuses = {
    CLOSED: "CLOSED",
    RESOLVED: "RESOLVED"
};

export const paginationTypes = {
    USER_REQUESTS: "USER_REQUESTS",
    USER_TRANSACTIONS: "USER_TRANSACTIONS",
    REQUESTS: "REQUESTS",
    USERS: "USERS",
    PENDING_PAYMENTS: "PENDING_PAYMENTS",
    CONFLICTS: "CONFLICTS"
};
