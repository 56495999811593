import { hideGlobalLoader, showGlobalLoader } from "./loaderActions";
import { hideDialog, showDialog, hideDialogLarge } from "./dialogActions";
import { createAxios as axios } from "../api/server";
import { actions } from "../constants/actions";
import { paginationTypes, userTypes } from "../constants/app";
import navigationUtils from "../utils/navigationUtils";
import { appUtils } from "../utils/appUtils";
import { strings } from "../resources/strings";

export const getUsers = (formValues, page) => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    let filterParams = {...formValues};
    filterParams.email = filterParams.emailFilter;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/users", {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        },
        params: {
            page,
            ...filterParams
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.GET_USERS, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());
};

export const getUserDetails = id => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/users/" + id, {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data, true);
    } else {
        const user = response.data.data;
        if (!user[user.userType.toLowerCase()]) {
            dispatch({type: actions.GET_USER_DETAILS, payload: user});
            dispatch(hideGlobalLoader());
            return;
        }
        const userTypeID = user[user.userType.toLowerCase()].id;
        dispatch(getUserRequests(user.id, userTypeID, user.userType, getState().pagination[paginationTypes.USER_REQUESTS]));
        if (user.userType === userTypes.ADVISOR) {
            dispatch(getUserTransactions(user.id, userTypeID, getState().pagination[paginationTypes.USER_TRANSACTIONS]));
        }
        dispatch({type: actions.GET_USER_DETAILS, payload: user});
    }

    dispatch(hideGlobalLoader());
};

export const getUserTransactions = (id, translatorId, page) => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/users/" + id + "/translators/" + translatorId + "/transactions", {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        },
        params: {
            page,
            perPage: 10
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.GET_USER_TRANSACTIONS, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());
};

export const getUserRequests = (id, userTypeID, userType, page) => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const subPath = "/" + userType.toLowerCase() + "s/";

    const response = await axios.get("admin/users/" + id + subPath + userTypeID + "/requests", {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        },
        params: {
            page
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.GET_USER_REQUESTS, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());
};

export const deleteUser = id => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.delete("admin/users/" + id, {
        headers: {
            "Authorization": "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch(showDialog(strings.success, strings.successfulDeletedUserMessage, {
            title: strings.close,
            action: () => {
                dispatch(hideDialog());
            }
        }));
        navigationUtils.goBack();
        dispatch({type: actions.DELETE_USER, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());

};

export const changeAccountStatus = (status, id) => async (dispatch, getState) => {

    const {myAccount} = getState().admin;
    dispatch(showGlobalLoader());

    const response = await axios.post("admin/translators/" + id + "/update-account-status", status, {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.UPDATE_ACCOUNT_STATUS, payload: response.data.data});

    }

    dispatch(hideDialogLarge());
    dispatch(hideGlobalLoader());

};

export const exportCSV = (formValues) => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    let filterParams = {...formValues};
    filterParams.email = filterParams.emailFilter;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/users-export", {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        },
        params: {
            ...filterParams
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    dispatch(hideGlobalLoader());
};

export const exportData = (exportDataType) => async (dispatch, getState) => {

    // exportDataType can be:
    // - clients
    // - translators
    // - requests
    // - transactions

    const supportedExportDataTypes = ["clients", "advisors", "requests", "transactions"];

    if (!supportedExportDataTypes.includes(exportDataType)) {
        // TODO show DEV error
        return;
    }

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/export/" + exportDataType, {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);

    } else {

        const exportFileName = `export_${exportDataType}_${new Date().toLocaleString().replace(/ |,|\//g, "_")}.csv`;

        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", exportFileName);
        document.body.appendChild(link);
        link.click();
    }

    dispatch(hideGlobalLoader());
};



export const getUserUncompletedPaidRequests = id => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/users/" + id + "/blocking-requests", {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    dispatch(hideGlobalLoader());

    return response;

};

export const blockUnblockUser = id => async (dispatch, getState) => {

    const {myAccount} = getState().admin;
    const {currentUser} = getState().users;
    const subPath = id + (currentUser.isBlocked ? "/unblock" : "/block");

    dispatch(showGlobalLoader());

    const response = await axios.post("admin/users/" + subPath, {}, {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        const dialogMessage = subPath.endsWith("unblock") ? strings.successfulUnblockedUser : strings.successfulBlockedUser;
        dispatch({type: actions.BLOCK_UNBLOCK_USER});
        dispatch(hideDialogLarge());
        dispatch(showDialog(strings.success, dialogMessage, {
            title: strings.close,
            action: () => {
                dispatch(hideDialog());
            }
        }));
    }

    dispatch(hideGlobalLoader());

};

export const getFile = filePath => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("file?filePath=" + filePath, {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        },
        responseType: 'blob'
    });

    if (response.status >= 400) {
        appUtils.errorHandle(response);
    } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        const file = filePath.split("/");
        const fileName = file[file.length-1];
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    dispatch(hideGlobalLoader());
};


export const getBlockedUsers = () => async (dispatch, getState) => {

    const {myAccount} = getState().admin;

    dispatch(showGlobalLoader());

    const response = await axios.get("admin/users-blocked", {
        headers: {
            Authorization: "Bearer " + myAccount.accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.GET_BLOCKED_USERS, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());
};
