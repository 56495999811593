import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

class ProgressDialog extends React.Component {

    render() {

        if (this.props.globalLoader) {
            return ReactDOM.createPortal(
                <div className="eclipse">
                    <div className="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>,
                document.querySelector("#loader")
            );
        }

        return <Fragment/>;
    }
}

const mapStateToProps = state => {
    return {globalLoader: state.loader.globalLoader};
};

export default connect(mapStateToProps)(ProgressDialog);