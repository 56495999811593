import React, { Component, Fragment } from "react";
import { Fields } from "../common/Fields";
import { reduxForm, getFormValues } from "redux-form";
import { formUtils } from "../../utils/formUtils";
import { connect } from "react-redux";
import { AccountStatuses } from "../../constants/app";

class AccountStatusForm extends Component {

    renderRejectReason = () => {

        const currentStatus = this.props.formValues ? this.props.formValues.newStatus : null;

        if (currentStatus === AccountStatuses.REJECTED) {
            return Fields.rejectReason;
        }
    };

    render() {
        return (
            <Fragment>
                { Fields.newStatus }
                { this.renderRejectReason() }
            </Fragment>
        );
    }
}

const mapStateToProps = state => {

    const newStatus = state.users.currentUser.translator.accountStatus.status !== AccountStatuses.REJECTED ? AccountStatuses.PUBLISHED : state.users.currentUser.translator.accountStatus.status;

    return {
        currentUser: state.users.currentUser,
        formValues: getFormValues("accountStatusForm")(state),
        initialValues: {newStatus}
    };
};

const accountStatusForm = reduxForm({
    form: "accountStatusForm",
    validate: formUtils.validate,
    onSubmit: formUtils.remoteSubmits.accountStatusSubmit
})(AccountStatusForm);

export default connect(mapStateToProps)(accountStatusForm);
