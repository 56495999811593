import { actions } from "../constants/actions";

const initState = {currentAdmin: null, admins: [], myAccount: null};

const adminReducer = (state = {...initState}, action) => {

    if (action.type === actions.SIGN_IN || action.type === actions.LOAD_ADMIN) {
        state.myAccount = {...action.payload};
        state.currentAdmin = {...action.payload};
        return {...state};
    }

    if (action.type === actions.SIGN_OUT) {
        state.myAccount = null;
        state.currentAdmin = null;
        return {...state};
    }

    if (action.type === actions.GET_ADMINS) {
        state.admins = [...action.payload];
        state.currentAdmin = {...state.myAccount};
        return {...state};
    }

    if (action.type === actions.GET_CURRENT_ADMIN) {
        state.currentAdmin = {...action.payload};
        return {...state};
    }

    if (action.type === actions.UPDATE_CURRENT_ADMIN) {
        state.currentAdmin = {...action.payload};
        if (action.payload.id === state.myAccount.id) {
            state.myAccount = {...action.payload};
            state.admins = state.admins.map(function(admin) {
                return admin.id === action.payload.id ? action.payload : admin;
            });
            state.admins = [...state.admins];
        }
        return {...state};
    }

    if (action.type === actions.UPDATE_PASSWORD) {
        state.myAccount.accessToken = action.payload.accessToken;
        state.myAccount = {...state.myAccount};
        return {...state};

    }

    return state;
};

export default adminReducer;