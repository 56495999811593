import { actions } from "../constants/actions";

const initState = {currentRequest: null, requests: {}};

const requestsReducer = (state = initState, action) => {

    if (action.type === actions.GET_REQUESTS) {
        state.requests = {...action.payload};
        state.currentRequest = {};
        state.currentRequest = {...state.currentRequest};
        return {...state};
    }

    if (action.type === actions.GET_REQUEST) {
        state.currentRequest = {...action.payload, startTime: +action.payload.startTime};
        return {...state};
    }

    return state;
};

export default requestsReducer;