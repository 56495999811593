import React, { Component, Fragment } from "react";
import navigationUtils from "../../utils/navigationUtils";
import { appUtils } from "../../utils/appUtils";
import { prefix } from "../../constants/pagesPaths";

class Navigator extends Component {

    renderBreadcrumbs() {

        const routeNames = navigationUtils.getRoute()
            .split("/")
            .slice(1 + (prefix.match(/\//g) || []).length);

        const routesViews = [];

        if (routeNames.length <= 1) {
            return null;
        }

        for (let i = 0; i < routeNames.length; i++) {

            if (i === (routeNames.length - 1)) {
                routesViews.push(this.renderRouteView(i, routeNames, true));
            } else {
                routesViews.push(this.renderRouteView(i, routeNames, false));
                routesViews.push(<span style={ {marginLeft: 5, marginRight: 5} } key={ i }>>></span>);
            }

        }

        return <span className="navigator">{ routesViews }</span>;

    }

    renderRouteView(index, routeNames, isLast) {

        const parsedRouteName = this.parsePathName(routeNames[index]);

        if (!isLast) {
            const navigationRoute = this.getNavigationRouteFromRoutesTillIndex(routeNames, index);
            return <a key={ parsedRouteName } href={ navigationRoute }>{ parsedRouteName }</a>;
        }

        return <span key={ parsedRouteName }>{ parsedRouteName }</span>;
    }

    getNavigationRouteFromRoutesTillIndex(routes, index) {

        let route = "/";

        for (let i = 0; i <= index; i++) {
            route += routes[i];
        }

        return route;
    }

    parsePathName(pathName) {
        let path = pathName.split("-");
        let refinedPath = "";

        path.forEach((name) => {
            refinedPath += appUtils.parseUpperFirstLetter(name) + " ";
        });

        return refinedPath;
    }

    render() {

        return (
            <Fragment>
                { this.renderBreadcrumbs() }
            </Fragment>
        );
    }
}

export default Navigator;
