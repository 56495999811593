import { combineReducers } from "redux";
import adminReducer from "./adminReducer";
import loaderReducer from "./loaderReducer";
import dialogReducer from "./dialogReducer";
import conflictReducer from "./conflictReducer";
import statisticReducer from "./statisticReducer";
import usersReducer from "./usersReducer";
import resourcesReducer from "./resourcesReducer";
import requestsReducer from "./requestsReducer";
import languagesReducer from "./languagesReducer";
import advisorsPaymentsReducer from "./advisorsPaymentsReducer";
import paginationReducer from "./paginationReducer";
import { reducer as formReducer } from "redux-form";

export default combineReducers({
    admin: adminReducer,
    loader: loaderReducer,
    dialog: dialogReducer,
    conflicts: conflictReducer,
    statistics: statisticReducer,
    users: usersReducer,
    resources: resourcesReducer,
    requests: requestsReducer,
    languages: languagesReducer,
    advisorsPayments: advisorsPaymentsReducer,
    pagination: paginationReducer,
    form: formReducer
});