import { hideGlobalLoader, showGlobalLoader } from "./loaderActions";
import { createAxios as axios } from "../api/server";
import { actions } from "../constants/actions";
import { appUtils } from "../utils/appUtils";

export const getExpertises = (accessToken) => async (dispatch, getState) => {

    dispatch(showGlobalLoader());

    const response = await axios.get("start/expertises", {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.GET_EXPERTISES, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());
};

export const getExpertiseCategories = (accessToken) => async (dispatch, getState) => {

    dispatch(showGlobalLoader());

    const response = await axios.get("start/expertiseCategories", {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    });

    if (response.data.error) {
        appUtils.errorHandle(response.data);
    } else {
        dispatch({type: actions.GET_EXPERTISE_CATEGORIES, payload: response.data.data});
    }

    dispatch(hideGlobalLoader());
};