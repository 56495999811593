import React, { Component } from "react";

class DefaultButton extends Component {
    render() {

        let className = "primary-button-container flex-fill";

        if (this.props.className) {
            className += " " + this.props.className;
        }

        if (this.props.danger) {
            className += " danger-button";
        }

        if (this.props.invert && !this.props.danger) {
            className += " invert-button";
        }

        if (this.props.invert && this.props.danger) {
            className += " invert-button-danger";
        }

        return (
            <div className={className} onClick={this.props.onPress}>
                <span className="primary-button-text">{ this.props.title }</span>
            </div>
        );
    }
}

export default DefaultButton;