import React, { Component } from "react";
import TopicSection from "../../common/TopicSection";
import { strings } from "../../../resources/strings";
import Table from "../../common/Table";
import { getBlockedUsers } from "../../../actions";
import { connect } from "react-redux";
import { appUtils } from "../../../utils/appUtils";

class BlockedUsersPage extends Component {

    componentWillMount() {
        this.props.getBlockedUsers();
    }

    renderUsers = () => {

        return <Table
            columns={ [strings.ID, strings.name, strings.type, strings.email, strings.completedRequests, strings.action] }
            data={ appUtils.parseUsers(this.props.blockedUsers) }
            noItemsMessage={ strings.noBlockedUsers }
        />;
    };

    render() {
        return (
            <div className="page-container flex-column flex-fill">
                <div className="flex-column flex-fill">
                    <TopicSection title={ strings.blockedUsers }/>
                    { this.renderUsers() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {blockedUsers: state.users.blockedUsers};
};

export default connect(mapStateToProps, {getBlockedUsers})(BlockedUsersPage);